import React from 'react';
import styles from './Styles.module.css';
import QuesDisplayContainer from './components/QuesDisplayContainer';
import PlayHeader from './components/PlayHeader';
import { useSelector} from 'react-redux';


const IndexView =()=>{
    //const dispatch = useDispatch();
    const {selectedQues,}= useSelector((state) => state.questionsReducer);
    const {quesSettings} = useSelector((state) => state.composReducer);
    
    
    return(
        <div className={styles.quiz_Cont}>
            <PlayHeader/>
           
            <div className={styles.subContainer}>
                <div className={styles.subContainer2}>
                    <QuesDisplayContainer selectedQues = {selectedQues}
                        quesSettings={quesSettings}/>
                </div>
            </div>
        </div>
    );
}



export default IndexView;


import React from "react";
import './HeaderStyle.css';
import { useSelector, useDispatch} from "react-redux";
import { disShowHeaderSbar, disHeaderModalSnack } from "../../context/reducers/composReducer";
import errorURL from "../../assets/sound/errorSound.mp3";



const HeaderModal =()=>{
    const dispatch = useDispatch();
    const {showHeaderSbar, headerModalSnack} = useSelector((state) => state.composReducer);
    const [showCont, setShowCont] = React.useState('');
    const [show, setShow] = React.useState('');

    function showSnackbar() {
        //setShowCont('block');
        setShow('HeaderMbarCont_Show');
        //setTimeout(fadeOut, 3000);
        //dispatch(disHeaderModalSnack({...headerModalSnack, show:}))
      }
    function hideSnackbar(act) {
        setShowCont('block');
        setShow('');
        //setTimeout(fadeOut, 3000);
        dispatch(disHeaderModalSnack({...headerModalSnack, show:false, action:act}));
      }
    
    React.useEffect(()=>{
        if(headerModalSnack.show){
            showSnackbar();
        }
    },[headerModalSnack.show]);
    
    return(
        <div style={{...styles.container, display: headerModalSnack.show? 'block' : 'none',}}>
            <div style={styles.contSub}>
                <div style={styles.snackcont}> 
                    <div style={styles.snack} 
                         className={`HeaderMbarCont ${show}`}>
                        <p>
                            {headerModalSnack.msg1}
                        </p>
                        <div style={{color:'white'}}>
                            {headerModalSnack.msg2}
                        </div>
                        <div className="HeaderMbar_Btns">
                            <div onClick={()=>hideSnackbar('CANCEL')}>Cancel</div>
                            <div onClick={()=>hideSnackbar('CONTINUE')}>{headerModalSnack.okayBtn}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const styles = {
    container:{
        position:'relative', 
        zIndex:15,
    },
    contSub:{
        backgroundColor:'rgba(250,250,250,0.5)',
        width:'100%',
        position:'absolute',
        height:'100vh',
    },
    snackcont:{
        width:'100%',
        height:'200px',
        backgroundColor:'',
        display:'flex',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
    },
    snack:{
        
    },
    btn:{
        position:'absolute', bottom:0,
        display:'none'
    }

}


export default HeaderModal
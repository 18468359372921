import React, { useEffect, useState } from 'react';
import genQuestions from '../../../assets/data/generalQues';
import { createQuesObjFromManyArrays } from '../../../modules/functionModules';
import HeaderBar from '../components/Header';
import { useSelector,} from 'react-redux';
import IndexNonAvail from '../components/nonAvail/Index';
import AvailQuestions from '../components/availQues/Index';

const IndexSelection =()=>{
    
    const {nonAvailQuesList,quizQues_Obj, loadingPercent, pickedQues,} = useSelector((state) => state.questionsReducer);
    const { playGame, } = useSelector((state) => state.userSettings);
    const loadingDone = loadingPercent.loadingPer === 100? true : false;
    const [questionsLen, setQuestionsLen] = useState(35);

    useEffect(()=>{
        const objCreated = createQuesObjFromManyArrays(genQuestions);
        setQuestionsLen(objCreated.length);
    },[])
    return(
        <div style={innerStyle.rightCard}>
            <HeaderBar playGame={playGame}/>
            <div style={innerStyle.header}>
                Questions File <span 
                    style={{...innerStyle.fileName}}>{quizQues_Obj.dyFileName}</span>
            </div>
            
            <div style={innerStyle.availCont}>
                <AvailQuestions/>
                <IndexNonAvail 
                    cursor
                    isPrevQues={true}
                    tColor="maroon"
                    colorSet="white"
                    bColor= "maroon"
                    headerText={"Non Available Questions"} 
                    mapList={nonAvailQuesList}
                    loadingDone={loadingDone}
                />
             </div>
        </div>
    );
}


const innerStyle ={
    rightCard:{flex:1,
        backgroundColor:'rgb(24, 61, 56)',
        margin:'10px',
        borderRadius:'10px',
        border:'2px solid ivory',
        //boxShadow:'2px 2px gray',
        padding:'5px',
        height: '95vh',
        display:'flex',
        flexDirection:'column',
        overflow:'wrap',
    },
    header: {
        padding:'0 10px 0 10px',
        justifyContent:'center',
        textAlign: 'center',
        backgroundColor:'seagreen',
        color:'white', padding:'5px',
        margin: '0',
        fontWeight:'bold',
        border: '2px solid gray',
        borderRadius:'10px',
      },
    selectCont:{
        backgroundColor:'pink',
        height:'70%',
        display:'flex',
        flexDirection:'row',
        
    },
    availCont:{
        padding:'10px',
    },
    fileName:{
        color:'yellow',
        //backgroundColor:'lime', 
        padding:'0px 5px 0px 5px', borderRadius:'5px'
    },
    headBar:{
        height:'30px',
        marginBottom:'5px',
        padding:'5px',
        border:'2px solid white',
        background: 'linear-gradient(rgb(100,105,105),rgb(5,5,15), rgb(5,5,15), rgb(5,5,15),rgb(105,105,105)',
        
    }
};
export default IndexSelection
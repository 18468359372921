import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {disHeaderTitle} from "../../../../context/reducers/composReducer";

const maxLen = 50;
const GameTitle = () => {
  const dispatch = useDispatch();
  const {headerTitle } = useSelector((state) => state.composReducer);
    
  //const [val, setVal] = React.useState('');
  const [len, setLen] = React.useState(maxLen);
  const [pressable, setPressable] = React.useState(true);

  
  React.useEffect(()=>{
    const strLen = headerTitle.length;
    const strLenLeft = maxLen - strLen;
    setLen(strLenLeft);
  },[headerTitle]);
  function onChangeHandler(e){
    if(pressable){
      //setVal(e.target.value)
      dispatch(disHeaderTitle(e.target.value))
    }
  }
  function onKeyPressHandler(e){
    //var key = e.keyCode || e.charCode; Backspace: key === 8 || key === 46
    if(len >0){
      setPressable(true)
    }else{
      if(e.key === "Backspace"){
        setPressable(true)
      }else{setPressable(false)}
    }
  }
  return (
    <div className='gameTitleCont'>
        <div>Set Game Title:</div>
        <input
            type="text"
            style={{marginLeft:'5px',padding:'5px', marginRight:'5px', display:'flex', flex:1}}
            placeholder='Enter game title'
            value={headerTitle}
            onChange={onChangeHandler}
            onKeyDown={onKeyPressHandler}
        />
        <div style={{color:'blue'}}>Length: {len}</div>
    </div>
  )
}

export default GameTitle;


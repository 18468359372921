import React, { useState, } from "react";
import * as Icon from 'react-bootstrap-icons';
import styles from '../Styles.module.css';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {disQuesNoList, disNonAvailQuesList,  disQuizQuesObj,disPlayText
    } from "../../context/reducers/questionsReducer";
import {disHeaderModalSnack
    } from "../../context/reducers/composReducer";
import {disNewFileUploadPrev, disFileDetails,disQuesLoader, disPlayGame} from "../../context/reducers/userReducer";
import Tooltip from "../tooltip/ToolTip";

const SelectedQuestionLoader =({len})=>{
    const dispatch = useDispatch();
    const {quesLoader,fileDetails,checkboxDuration,
         newFileUploadPrev, playGame, } = useSelector((state) => state.userSettings);
    const {headerModalSnack } = useSelector((state) => state.composReducer);
    const loaderWidth = quesLoader.loaderWidth;
    const [val, setVal] = useState(0);
    const navigate = useNavigate();

    var i =0;
    function runLoad(){
        const fullPercent = 100;
        if(i === 0){
            i = 1;
            var width =1;
            var id = setInterval(frame, 20);

            function frame(){
                if(width >= fullPercent){
                    clearInterval(id);
                    i = 0;
                }else{
                    width++;
                    const itemLen = len? len : 0;
                    const itemQuotient = Math.floor((width/100*itemLen));
                    setVal(itemQuotient);
                    dispatch(disQuesLoader({loadingPer: width, loadingVal: itemQuotient, loaderWidth:width}))
                }
            }
        }
    }
    function loadQuesHandler(){
        if(newFileUploadPrev.prevQues.length>0){
            if(loaderWidth === 0){runLoad();};
        }
       
    }
    const loadAndPlayHandler =()=>{
        const qNoToLoad = newFileUploadPrev.prevQues.filter((item)=>{
            return !isNaN(item.sn)
        });
        const quizQues_Obj= {prevQues:qNoToLoad,
            fileName:newFileUploadPrev.fileName, 
            dyFileName:newFileUploadPrev.dyFileName, play:true};
            dispatch(disQuizQuesObj(quizQues_Obj))
        
        /**** Load questions */
        const arr = Array.from({length: fileDetails.fileLen}, (_, i)=> i+1);
        dispatch(disQuesNoList(arr));
        dispatch(disNonAvailQuesList([]))
        
        //Reset Action
        dispatch(disHeaderModalSnack({...headerModalSnack, action:""}));
        //Navigate to Play Screen
        dispatch(disPlayGame({...playGame, start:true}))
        navigate('/play');
    }
    const playGamehandler=()=>{
        if(!checkboxDuration.display){
            dispatch(disHeaderModalSnack({...headerModalSnack, msg1:msg1, msg2:msg2, show:true}));
        }else{
            loadAndPlayHandler();
        }
    }
    const cancelGamehandler = ()=>{
        dispatch(disQuizQuesObj({prevQues:[], fileName:'', dyFileName:'', play:true}));
        dispatch(disNewFileUploadPrev({correctOptColor:null, prevQues:[], fileName:'', dyFileName:'', fileNo:''}));
        dispatch(disFileDetails({fileName:'', fileLen:''})); 
        dispatch(disQuesLoader({loadingPer: '', loadingVal: '', loaderWidth:0}));

        /**** Reset questions */
        dispatch(disQuesNoList([]));
        dispatch(disNonAvailQuesList([]))
    }
    const element ={
        width: `${loaderWidth}%`,
        marginLeft:loaderWidth===0? '5px':null,
    }

    React.useEffect(()=>{
        if(headerModalSnack.action){ //If action button is PRESSED
            if(headerModalSnack.action === "CONTINUE"){
                loadAndPlayHandler();
            }
        }

    },[headerModalSnack]);
    return(
        <div style={innerStyle.cont}>
            <div style={{display:'flex', flexDirection:'row'}}>
                <div className={
                    newFileUploadPrev.prevQues.length>0? styles.loadBtnActive : styles.loadBtnNonActive} 
                    onClick={loadQuesHandler}>
                    Load File
                </div>
                <div style={innerStyle.loadinBarCont}>
                    <div style={{...element, ...innerStyle.element}}>{loaderWidth+'%'}</div>
                </div>
                {loaderWidth < 100? null :
                     <div style={innerStyle.playCont}>
                        <Tooltip text="Click to Play">
                            <Icon.FilePlayFill 
                                size={28} color="green"
                                style={innerStyle.filePlay}
                                onClick={playGamehandler}/>
                        </Tooltip>
                        <Tooltip text="Cancel">
                            <Icon.XCircleFill 
                            size={28} color="red"
                            style={innerStyle.filePlay}
                            onClick={cancelGamehandler}/>
                        </Tooltip>
                     </div>
                    }
            </div>
        </div>
    );
}

export default SelectedQuestionLoader
const msg1 =`You have not set Time Duration to answer a question`;
const msg2 = `Press "Cancel" to set it or "Continue" without setting it`;

const innerStyle ={
    cont:{padding:'10px', paddingTop: 0, marginTop: '-5px',
        zIndex:10,
    },
    loadinBarCont: {
        backgroundColor:'silver', width:'60%', borderRadius:'10px',
        margin:'5px',

    },
    filePlay:{
        marginTop:'3px',
    },
    playCont:{
        display:'flex', flexDirection:'row',
        width:'65px',
        justifyContent:'space-between'
    },
    element:{
        backgroundColor:'lime',
        height:'25px',
        textAlign:'center',
        lineHeight:'25px',
        fontSize:'12px',
        boxShadow: 'inset 0px 1px 0px 0px #bee2f9',
        background: 'linear-gradient(to bottom, #63b8ee 5%, mediumblue',
        borderRadius: '10px', 
        color:'white',
    }
}
import React from "react";
const XLSX = require('xlsx');


class SampleFileDownload extends React.Component {
  constructor(props) {
    super(props)
    
    const defaultFileType = "csv"; 
    this.fileNames = {
    	csv: "quizApp-sampleFile.csv",
      xlsx: "quizApp-sampleFile.xlsx",
      text: "quizApp-sampleFile.txt",
    }    
    this.state = {
      fileType: defaultFileType,
      fileDownloadUrl: null,
      status: "",
      datas: [
        { sn: 1, ques: "Which animal is known as the 'Ship of the Desert'?", opt1: "Camel",opt2: "Elephant",opt3: "Horse",opt4: "Lion", },
        { sn: 2, ques: "How many days are in a week?", opt1: "7",opt2: "4",opt3: "14",opt4: "10", },
        { sn: 3, ques: "The first man created on Earth by God was?", opt1: "Adam",opt2: "Eve",opt3: "Satan",opt4: "Abraham", },
        { sn: 4, ques: "Nigeria got independence on what year?", opt1: "1960",opt2: "1963",opt3: "1950",opt4: "1958", },
        { sn: 5, ques: "How many letters are there in the English alphabet?", opt1: "26",opt2: "23",opt3: "27",opt4: "25", },
        { sn: 6, ques: "Rainbow consist of how many colours?", opt1: "7 Colors",opt2: "9 Colors",opt3: "12 Colors",opt4: "5 Colors", },
        { sn: 7, ques: "Baby frog is known as.......", opt1: "Tadpole",opt2: "Tappole",opt3: "Lavar",opt4: "kitten", },
        { sn: 8, ques: "How many vowels are there in the English alphabet?", opt1: "5",opt2: "7",opt3: "6",opt4: "4", },
        { sn: 9, ques: "How many continents are there in the world?", opt1: "7",opt2: "8",opt3: "9",opt4: "6", },
        { sn: 10, ques: "Which is the largest planet in the world?", opt1: "Jupiter",opt2: "Mars",opt3: "Earth",opt4: "Mercury", },   
    ],
    dataHeader: ["S/N", "Question", "Option 1", "Option 2", "Option 3", "Option 4"],
    dataHeaderObj: {sn:"S/N", ques:"Question",opt1: "Option 1", opt2:"Option 2", opt3:"Option 3", opt4:"Option 4"},
    }
    this.changeFileType = this.changeFileType.bind(this);
  	this.download = this.download.bind(this);
  }
  
  changeFileType (event) {
    const value = event.target.value;
  	this.setState({fileType: value});
  }
  
  download (event) {
    event.preventDefault();
  	// Prepare the file
    let output;
    if (this.state.fileType === "csv"){
      // Prepare data:
      let contents = [];
      contents.push(this.state.dataHeader);
      this.state.datas.forEach(row => {
      	contents.push([row.sn, row.ques, row.opt1, row.opt2, row.opt3, row.opt4])
      });
      output = this.makeCSV(contents);
    }else if (this.state.fileType === "text"){
      // Prepare data:
      output = '';
      const newData = [this.state.dataHeaderObj, ...this.state.datas];
      newData.forEach(row => {
          output += `${row.sn}\t${row.ques}\t${row.opt1}\t${row.opt2}\t${row.opt3}\t${row.opt4}\n`
      });
    }
    if (this.state.fileType === "xlsx"){
        //const data = [this.state.dataHeader, ...this.state.datas];
        const aoas = [this.state.dataHeader];
        for (let i = 0; i < this.state.datas.length; i++) {
          const arr = Object.values(this.state.datas[i]);
          aoas.push(arr);
        }
        const workSheet = XLSX.utils.aoa_to_sheet(aoas);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "SampleFile");
        XLSX.writeFile(workBook, ".quizApp/sampleFile.xlsx"); 

        return
    }
    // Download it
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    this.setState ({fileDownloadUrl: fileDownloadUrl}, 
      () => {
        this.dofileDownload.click(); 
        URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
        this.setState({fileDownloadUrl: ""})
    })    
  }

  /**
   * Function returns the content as a CSV string
   * See https://stackoverflow.com/a/20623188/64904
   * Parameter content:
   *   [
   *.     [header1, header2],
   *.     [data1, data2]
   *.     ...
   *.  ]
   * NB Does not support Date items
   */
  makeCSV (content) {
  	let csv = '';
    content.forEach(value => {
    	value.forEach((item, i) => {
        let innerValue = item === null ? '' : item.toString();
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"'
        }
        if (i > 0) {csv += ','}
        csv += result;
      })
    	csv += '\n';
	  })
    return csv
  }
  
  /**
   * Process the file within the React app. We're NOT uploading it to the server!
   */
  openFile(evt) {
      let status = []; // Status output
      const fileObj = evt.target.files[0];
      const reader = new FileReader();
          
      let fileloaded = e => {
        // e.target.result is the file's content as text
        const fileContents = e.target.result;
        status.push(`File name: "${fileObj.name}". Length: ${fileContents.length} bytes.`);
        // Show first 80 characters of the file
        const first80char = fileContents.substring(0,80);
        status.push (`First 80 characters of the file:\n${first80char}`)
        this.setState ({status: status.join("\n")})
      }
      
      // Mainline of the method
      fileloaded = fileloaded.bind(this);
      reader.onload = fileloaded;
      reader.readAsText(fileObj);  
  }
  
  render() {
    return (
      <div>
        <form>
          
            <select name="fileType"
              onChange={this.changeFileType}
              value={this.state.fileType}
              className="mr"
            >
              <option value="csv">CSV</option>
              <option value="xlsx">Excel</option>
              <option value="text">Text</option>
            </select>
            <button onClick={this.download}>
              Download File Sample
            </button>
          
          <a style={{visibility: 'hidden'}}
             download={this.fileNames[this.state.fileType]}
             href={this.state.fileDownloadUrl}
             ref={e=>this.dofileDownload = e}
          >download it</a>
          
        </form>
      </div>
	  )
  }
}

export default SampleFileDownload
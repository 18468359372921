import React, {useState,  } from 'react';
import HeaderContainer from './HeaderContainer';
import HeaderSnackbar from './HeaderSnackbar';
import HeaderModal from './HeaderModal';


const HeaderIndex =(props)=> {
    
    

    return(
        <div>
            <HeaderSnackbar/>
            <HeaderModal/>
            <HeaderContainer/>
        </div>     
    );
}


const innerStyle ={

};
export default HeaderIndex

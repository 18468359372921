

  function validateExcelFile(data, fileType){
    /****data = [['S/N', 'Ques', 'Opt1', 'Opt2', 'Opt3', 'Opt4'], [],[]]
         ******/
    const fileRowLen = 6; //Expected
    const lineType = fileType === 'txt'? "Line" : "Row";

      let errorsContainer =[];
      //Check if question has header and starts from 1.
      if(!isNaN(parseInt(data[1])[0])){
        const errorMsg = `Your file must have a header`;
        errorsContainer.push(errorMsg);
      };
      for(let i = 0; i < data.length; i++){
        const rows = data[i];
        const rowNo = i +1;
        const rowLen = rows.length;
        //Check if serial numbers are orderly
        if(rowNo !== parseInt(rows[0])+1){
          let errorMsg = `Incorrect serial number at ${lineType} ${rowNo}`;
          errorMsg = i === 0? null : errorMsg; //Ignore first row
          if(errorMsg){errorsContainer.push(errorMsg);}
        };
        //Checkall the rows length.
        if(rowLen > fileRowLen){
          const errorMsg = `Invalid ${lineType} length at ${lineType} ${rowNo}`;
          errorsContainer.push(errorMsg);
        };
        for(let j = 0; j < fileRowLen; j++){
          let cols = rows[j];
          const colNo = j +1;
          if(!cols){
            let errorMsg = `Invalid cell at ${lineType} number ${rowNo}, Column number ${colNo} with ${rows[j]}`;
            errorsContainer.push(errorMsg);
          }
        }
      }

    return errorsContainer
  };



     //Validate
 function validateTextFile(aoa){  //Array of arrays
        const cellsInLine = 6;
        const errorMessages = [];
        for (var i = 0; i < aoa.length; i++) {
          const lineText = aoa[i];
          const emptyCell = lineText.includes("") || lineText.includes(null);
          const lineNo = i +1;
          const currentLSN = i === 0? 0 : parseInt(lineText[0], 10);
          const prevLSN = i === 0? 0 : currentLSN -1;
 
          if(lineText.length !== cellsInLine || emptyCell){
            //Check if each line is complete, ie, with 6cells
            const errorMsg1 = `Header Error!. Line 1 (Header) does not contain one of the required values`;
            const errorMsg2 = `Line ${lineNo} does not contain either serial, question or one of the options`;
            const errorMsg = i === 0? errorMsg1 : errorMsg2;
            errorMessages.push(errorMsg);
          }
          if(isNaN(currentLSN)){
              //Check if first value in a line is a number
              const errorMsg = `Serial Number error in Line ${lineNo}`;
               errorMessages.push(errorMsg);
            }
           const snError = i === 0? false : currentLSN !== prevLSN +1;
           if(snError){
              //Check serial number order
              const errorMsg = `Line ${lineNo} is not numbered properly`;
              errorMessages.push(errorMsg);
            }
            //errorMessages.push(prevLSN)
        }

        return errorMessages
     }

     
  export {validateExcelFile, validateTextFile}


  
  function readTextFile(evt){
    let status = []; // Status output
    const fileObj = evt.target.files[0];
    const reader = new FileReader();
        
    let fileloaded = e => {
      // e.target.result is the file's content as text
      const fileContents = e.target.result;
      //console.log(fileContents);
      status.push(`File name: "${fileObj.name}". Length: ${fileContents.length} bytes.`);
      // Show first 80 characters of the file
      const first80char = fileContents.substring(0,500);
      status.push (`First 80 characters of the file:\n${first80char}`)
      this.setState ({status: status.join("\n")});

      // Count of lines in file
      var lines2 = fileContents.split("\n").length;

      // Attempt at an action per line
      var lines = fileContents.split('\n');
      const fileContentsArr = [];
      for (var line = 0; line < lines.length; line++) {
          const lineText = lines[line];
          const cells = lineText.split('\t');
          fileContentsArr.push(cells);
        }
        //console.log(fileContentsArr);
      this.validateTextFile(fileContentsArr);
    }
    
    // Mainline of the method
    fileloaded = fileloaded.bind(this);
    reader.onload = fileloaded;
    reader.readAsText(fileObj);  
}

import React from 'react';
import '../Styles.css'; // Import regular stylesheet
import styles from '../Styles.module.css'; // Import css modules stylesheet as styles
import wrongURL from "../../../../assets/sound/wrongSound.mp3";
import { disShowHeaderSbar } from '../../../../context/reducers/composReducer';
import { disPlayerClock } from '../../../../context/reducers/composReducer';
import {disCheckbokDuration} from '../../../../context/reducers/userReducer';

import { useSelector, useDispatch } from 'react-redux';
import { disSelectedQues, disAvailQuesList, disNonAvailQuesList, disLoaderWidth, 
    disOptFormat, disPlayText, disOptIsSelected, disStartQuizQues, 
    disPlaybackStarted, disCurrentDisplayQ, disQuesNoList, disPickedQues} from "../../../../context/reducers/questionsReducer";


const AvailQuestions =()=>{
    const dispatch = useDispatch();
    const {quizQues_Obj,availQuesList, nonAvailQuesList, quesNoList, optIsSelected,
       pickedQues, currentDisplayQ}= useSelector((state) => state.questionsReducer);
    const {playersSettings, playersInputs, }= useSelector((state) => state.playerReducer);
    const {showHeaderSbar, playerClock} = useSelector((state) => state.composReducer);
    const {checkboxDuration } = useSelector((state) => state.userSettings);
    const startGameIsOn =  true; //playSettings.start;
    
    
    const availableQues =(sel)=>{
     const list = [...availQuesList];
     let filetered = list.filter((items)=>{
        return items !==  sel
     })
       return filetered
    }

    const pickedNoCall = (no)=>{
        if(!nonAvailQuesList.includes(no)){
            const newnonAvailQues = [...nonAvailQuesList, no];
            newnonAvailQues.sort((a,b)=> a - b);
            dispatch(disNonAvailQuesList(newnonAvailQues));
        }
        dispatch(disPlayerClock({...playerClock, playerTimer:1}));
        //Reset PlayHeader Format
        //dispatch(disPickedQues({...pickedQues, playbackStarted:false}));
        dispatch(disPlayText({header: "LET'S PLAY",header2:'', bgColor: 'rgb(24, 61, 56)'}));

        const newAval = availableQues(no);
        dispatch(disAvailQuesList(newAval));
        dispatch(disPickedQues({...pickedQues, pickedNo:no, playbackStarted:false, pickedIsAns: false}));
        dispatch(disCheckbokDuration({...checkboxDuration, startCountDn:true, countDnStarted:true}));

        //Set Selected Question
        var selNo = quizQues_Obj.prevQues.filter((item)=>{
            return item.sn === no
        });
        selNo = {...selNo[0]};
        dispatch(disSelectedQues(selNo));

        //Reset options format
        const defaultFmat = {color:'white', backgroundColor:'rgb(8, 3, 20)', borderColor:'white'};
        const resetFoart = {'1': defaultFmat,'2': defaultFmat,'3': defaultFmat,'4': defaultFmat,} 
        dispatch(disOptFormat(resetFoart))
        dispatch(disOptIsSelected(false));
        dispatch(disPlaybackStarted(false));
        const currentQ = {qNo:no, optIsSelected:false, optNoSelected:'', 
            ansChecked:false, correctOpt:'',rightAnswer:''};
        dispatch(disCurrentDisplayQ(currentQ));

        if(nonAvailQuesList.length ===0){
            dispatch(disStartQuizQues(true))
        };
        let newAvailQues = quesNoList.filter((nos)=> {return nos !==  no});
        dispatch(disQuesNoList(newAvailQues)); 
        setQuestionCount();
    }
    const pickedNoHandler =(no)=>{
        const optNotSeltd ="Please, pick an option and check the answer before moving to Next Question!"; 
        const ansNotChkd ="Check the answer before moving to the Next Question please!";  
        if(nonAvailQuesList.length > 0){
            if(currentDisplayQ.optIsSelected){
                if(currentDisplayQ.ansChecked){
                    pickedNoCall(no);
                }else{
                    dispatch(disShowHeaderSbar({...showHeaderSbar, msg:ansNotChkd, show:true}))
                }
            }else{dispatch(disShowHeaderSbar({...showHeaderSbar, msg:optNotSeltd, show:true}))}
        }else{pickedNoCall(no);}
    }
    const availQuesCheckHandler=(no)=>{
        if(!nonAvailQuesList.includes(no)){
            return styles['quesBtns_default']
        }else{ return styles['quesBtns_picked']}
    }
    const resetQuestionsHandler=()=>{
        const arrLen = Array.from({length: 40}, (_, i)=> i+1);
        dispatch(disAvailQuesList([]));
        dispatch(disNonAvailQuesList([]));
        dispatch(disLoaderWidth(0));
        dispatch(disStartQuizQues(false));
    }
    
    function setQuestionCount(){
        const currentCount = playersSettings.quesCount+1;
    }

    function setNextRound(count){
        const currentRound = parseInt(playersSettings.currentRound);
        const currentCount = parseInt(playersSettings.quesCount);
        let nextRound ='';
        const noOfPlayers = parseInt(playersInputs.noOfPlayers);
            if(count <=1){
                nextRound = 1;
            }else{
                if(currentCount % noOfPlayers == 1){
                    nextRound = currentRound+1;
                }else{nextRound=currentRound};
            }
    };

    
    return(
        <div style={innerStyle.container}>
            <span style={innerStyle.avail}>Available: {quesNoList.length}</span>
            <div style={innerStyle.subcont}>
                {quesNoList.length > 0? 
                    quesNoList.map((item, index)=>{
                        return(
                            <div key={index+'myKey'} 
                                className={`${availQuesCheckHandler(item)}`}
                                onClick={()=>pickedNoHandler(item)
                            }>
                                <p className={styles.quesBtnsText}>
                                    {item}
                                </p>
                            </div> 
                        );}) :
                    <p style={innerStyle.upload}>
                        {nonAvailQuesList.length>0? "" : 'Load Questions to Play'}
                    </p>
                }
            </div>
        </div>
    );
}


export default AvailQuestions;





const innerStyle ={
    container:{
        border: '2px solid ivory',
        borderRadius: '10px',
    },
    subcont:{
        padding: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        overflowY: 'scroll',
        height: '40vh',
    },
    avail:{
        color:'white', fontWeight:'bold',
        justifyContent:'center', display:'flex',
        borderBottom: '2px solid gray',
        padding: '5px',
        fontSize:'20px',
    },
    upload:{
        color:'red', 
        textAlign:'center',
        //textShadow: '2px 2px white',

    },
};

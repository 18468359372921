import React from 'react';
import styles from '../Styles.module.css';
import FileUploader from '../../../utils/fileupload/Fileuploader';
import SelectUploadedFile from './components/SelectUploadedFiles';
import { useSelector, } from 'react-redux';
import JsFileDownloader from 'js-file-downloader';
//import SampleFileDownload from './components/DownloadSampleFile';
import * as Icon from 'react-bootstrap-icons';
import Tooltip from '../../../utils/tooltip/ToolTip';

const fileUrl = "../../../../public/resource/Acceptance.pdf";

const UploadIndex=()=>{
    const {newFileUpload, userFetchedData, fileDetails} = useSelector((state) => state.userSettings);
    
    function downloadSampleFile(){
        new JsFileDownloader({ 
            url: fileUrl
          })
          .then(function () {
            alert("File successfully downloaded")
          })
          .catch(function (error) {
            // Called when an error occurred
          });
      }
    function getFileName(file, type){
       let stgFile =  file.toString().split('.');
       const name = stgFile[0];
       const fmt = stgFile.pop(); const fmts = {csv: 'comma-separated values (csv)', xlsx:'Excel (xlsx)',xls:'Excel (xls)',xlsxm:'Excel (xlsm)',txt:"Text (txt)" }
       const res = [name,fmts[fmt]];
       return file? res[type] : null
    }

    return(
        <div className={styles.rtContainer}>
            <div className={styles.rtSelectedCont}>
                <p className={styles.rtSelectedQText}>File Management</p>    
            </div>
            <FileUploader 
                labelTopStyle={{color:'black'}}
                labelBottomStyle={{...innerStyle.labBot, visibility:'hidden'}}
                sucStyle={'lime'}
                nonSucStyle={'black'}
                inputStyle={{color:'blue'}}
            />
            <div className={`${styles.fileDetailsCont} 
                ${newFileUpload.uploadStatus? styles.elementVisible:styles.elementVisible}`}>
                <div>Selected File details:</div>
                <div>
                    File name:<span>{getFileName(fileDetails.fileName, 0)}</span>
                </div>
                <div>
                    File format:<span>{getFileName(fileDetails.fileName, 1)}</span>
                </div>
                <div>
                    No of questions:<span>{fileDetails.fileLen}</span>
                </div>
            </div>
            <div style={innerStyle.uploaded}>
                
                {/*<Tooltip text="Delete all the uploaded files"
                    bColor="maroon">
                    <Icon.TrashFill color='red' size={18}/>
                </Tooltip>*/}
                Uploaded Files
            </div>
            <div className={styles.filesListCont}>
                <div className={styles.filesListCont_Sub}>
                    <div style={innerStyle.savedFiles}>Select File - 
                        {`${userFetchedData.length} upload${userFetchedData.length> 1? "s" : ""}`}</div>
                    <div className={styles.savedFilesCont}>
                        <SelectUploadedFile/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadIndex


const innerStyle ={
    container:{
        backgroundColor: 'ivory',
        display: 'flex',
        height: '85vh',
        margin:'5px',
        border:'2px solid gray',
        borderRadius:'10px',
        justifyContent:'flex-start',
    },
    labBot:{
        fontSize:'12px', backgroundColor:'gray',
        padding:'0 5px 0 5px'
    },
    savedFiles:{
        textAlign:'center', 
    },
    uploaded:{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent:'center',
        backgroundColor: 'rgb(1, 1, 19)',
        margin: '10px 10px 0px 10px',
        borderRadius: '5px',
        padding: '5px',
        height:'25px',
        color:'white',
    }
}

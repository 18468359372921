import React, { useEffect, useState} from 'react';
import DeleteFileHandler from './BtnDelete';
import { useSelector, useDispatch } from 'react-redux';
import { disNewFileUploadPrev,disResetFileUpload, disUserFetchedData, disFileDetails, disQuesLoader, } from '../../../../context/reducers/userReducer';
import { disShowHeaderSbar } from '../../../../context/reducers/composReducer';

const SelectUploadedFile =()=>{
 const dispatch = useDispatch();
 const {userFetchedData,checkboxMyPage, newFileUpload, newFileUploadPrev, 
        deleteFile, fileDetails, playGame}= useSelector((state) => state.userSettings);
 const {showHeaderSbar} = useSelector((state) => state.composReducer);
 const [selectedFile, setSelectedFile] = useState(0);
 


 
 const selectedFileHandler=(file, indexNo,)=>{
    if(!playGame.start){ //Can't select file while Game is ON
        const prevSet = {
            correctOptColor:checkboxMyPage.correctOpt? 'lime' : null, 
            prevQues:file.prosData,
            fileName:file.fileName, dyFileName:file.dyFileName, fileNo:indexNo,
        };
        dispatch(disNewFileUploadPrev(prevSet));
        setSelectedFile(indexNo);
        dispatch(disFileDetails({fileName:file.fileName, fileLen:file.fileLen})); 
        dispatch(disQuesLoader({loadingPer: '', loadingVal: '', loaderWidth:0}));
        dispatch(disResetFileUpload());
    }else{
        const msg ="Game is currently playing, you can't select another file! To select a file, first cancel the game";
        dispatch(disShowHeaderSbar({...showHeaderSbar, msg:msg, show:true}))
    }
 }
 function fetchSavedData(data){
    dispatch(disUserFetchedData(data))
 }
 function savedFilesChecker(){
    const usersFileData = localStorage.getItem('QuizApp@UserData');
    if(usersFileData){
        let savedItemsObj = JSON.parse(usersFileData);
        fetchSavedData(savedItemsObj);
    }
 }


 function watchFileChange(){
    const usersFileData = localStorage.getItem('QuizApp@UserData');
    var newSavedItemsObj = "";
    if(newFileUpload.uploadStatus){ //Execute pnly when there is change
        if(usersFileData){
            const isFileExist = userFetchedData.find((fl)=>{ 
                return fl.fileName === newFileUpload.fileName});
            
            if(!isFileExist){
                let savedItemsObj = JSON.parse(usersFileData);
                newSavedItemsObj = [...savedItemsObj, newFileUpload];
            }else{
                alert("File already exist");
                newSavedItemsObj = userFetchedData;
            }
            
        }else{ newSavedItemsObj = [newFileUpload];}
        
        localStorage.setItem('QuizApp@UserData', JSON.stringify(newSavedItemsObj));
        fetchSavedData(newSavedItemsObj);
    }
 }
 const updatePreviewIfFile = (fileToDelete)=>{
    //If preview display file is the same as save to be saved, update
     const prevSet = {...newFileUploadPrev, 
         fileName:'', dyFileName:'',
         prevQues:'', fileNo:""};
      if(newFileUploadPrev.fileName === fileToDelete.fileName){
          dispatch(disNewFileUploadPrev(prevSet));
      }
 }
 function deleteSelectedFile(deleteFile){
   const newUserFetchedData =  userFetchedData.filter((lst, i)=>{
        return lst.fileName !== deleteFile.fileName
    });
    localStorage.setItem('QuizApp@UserData', JSON.stringify(newUserFetchedData));
    fetchSavedData(newUserFetchedData);
    updatePreviewIfFile(deleteFile);
    if(deleteFile.fileName === fileDetails.fileName){
        dispatch(disFileDetails({fileName:"", fileLen:""}));
    }
 };

 useEffect(()=>{
    if(deleteFile.fileName){
        deleteSelectedFile(deleteFile);
    }
 },[deleteFile]);

 useEffect(()=>{
    watchFileChange();
 },[newFileUpload]);

 useEffect(()=>{
    savedFilesChecker();
 },[]);

 return(
     <>
        {
            userFetchedData.map((objItem,index)=>{
                return (
                <div style={{...innerStyle.listCont, 
                    backgroundColor:index+1===selectedFile? 'rgb(100, 248, 174)': null}} key={index+'userFiles'}>
                    <div
                        onClick={()=>selectedFileHandler(objItem, index+1)}
                        style={innerStyle.listText}>
                        {index+1}. {objItem.fileName}
                    </div>
                    <DeleteFileHandler
                        file={objItem}
                        userFetchedData={userFetchedData}
                        newFileUpload={newFileUpload}
                        newFileUploadPrev= {newFileUploadPrev}/>
                </div>
                
                );
            })}
     </>
 );
}

const innerStyle ={
    span:{
        float:'right',
        marginTop:'-2px',
        padding: '1px 5px 0px 5px',
    },
    listCont:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    listText:{
        textOverflow:'ellipsis',
    }
}
export default SelectUploadedFile

import React from 'react';
import styles from '../../Styles.module.css';
import { shuffleArray } from '../../../../modules/functionModules';
import { useSelector, useDispatch } from 'react-redux';
import { disNewFileUploadPrev, disCheckboxMyPage, disCheckbokDuration,
     } from '../../../../context/reducers/userReducer';
import Tooltip from '../../../../utils/tooltip/ToolTip';

const BtnsMain=()=>{
    const dispatch = useDispatch();
    const {newFileUploadPrev, checkboxMyPage,
        quesLoader,checkboxDuration } = useSelector((state) => state.userSettings);
    const loaderWidth =quesLoader.loaderWidth;
   
   const toggleCorrectOpt =()=>{
       const newCorrectOpt = {...checkboxMyPage, correctOpt: !checkboxMyPage.correctOpt};
       dispatch(disCheckboxMyPage(newCorrectOpt))
       const col = newCorrectOpt.correctOpt? "lime" : null;
        dispatch(disNewFileUploadPrev({...newFileUploadPrev, correctOptColor:col}))
        //setShowCorrectOpt(col? '':'');
        //setChecked(!checked);

   }
   const toggleDisplayQuestions =()=>{
        const newDisplayQues = {...checkboxMyPage, display: !checkboxMyPage.display};
        dispatch(disCheckboxMyPage(newDisplayQues))
    }
    const toggleTimeDuration =()=>{
        const newCheckbox = {...checkboxDuration, display: !checkboxDuration.display};
        dispatch(disCheckbokDuration(newCheckbox))
    }
    const duSelectionHandler =(e)=>{
        dispatch(disCheckbokDuration({...checkboxDuration, selVal: e.target.value}));
        
    }
    const btnEffectActive = newFileUploadPrev.prevQues && loaderWidth === 0 
                && newFileUploadPrev.fileName && checkboxMyPage.display? true : false;
    const btnEffect = btnEffectActive? styles.shuffleBtnsActive: styles.shuffleBtnsNonActive;
    
    const shuffOptsHandler=()=>{
        if(btnEffectActive){
                const datas = newFileUploadPrev.prevQues; 
                const shuffList = datas.map((item, index)=>{
                    const opts = [item['1'], item['2'], item['3'], item['4']];
                    const res = shuffleArray(opts);
                    const newDatas = {...item, '1':res[0],'2':res[1],'3':res[2],
                        '4':res[3],};
                    return newDatas
                })
                const finalData = {...newFileUploadPrev, prevQues: shuffList};
                dispatch(disNewFileUploadPrev(finalData))
        }
   }
   const shuffleQuesHandler=()=>{
       if(btnEffectActive){
                const datas = newFileUploadPrev.prevQues;
                const qSNZeroObj = datas.find((item)=>{
                    return isNaN(item.sn)
                });
                const othersObj = datas.filter((item)=>{
                    const check = item.sn !== qSNZeroObj.sn; 
                    return check
                });
                const shuffleOtherObj = shuffleArray(othersObj);
                const sortOtherObj = shuffleOtherObj.map((item,index)=>{
                    return {...item, sn:index+1}
                });
                const shufAndSorted = [qSNZeroObj, ...sortOtherObj];
                const finalData = {...newFileUploadPrev, prevQues: shufAndSorted}
                dispatch(disNewFileUploadPrev(finalData))
       }
   }

    return(
        <>
            <div className={styles.ltPrevCont}>
                <div style={innerStyle.checkBoxCont}>
                    <input type="checkbox" name='display' 
                        style={innerStyle.checkBox}
                        onChange={toggleDisplayQuestions}
                        checked={checkboxMyPage.display}
                        />
                    <label htmlFor="display" >Display Questions</label>
                </div>
                <div style={innerStyle.checkBoxCont}>
                    <input type="checkbox" name='display' 
                        //value={showCorrectOpt}
                        value={checkboxMyPage.correctOpt}
                        style={{...innerStyle.checkBox, marginLeft:'15px'}}
                        onChange={toggleCorrectOpt}
                        checked={checkboxMyPage.correctOpt}
                        />
                    <label htmlFor="display" >Show Correct options</label>
                </div>
                <Tooltip text="Check this box to set duration time for a question">
                    <div style={innerStyle.checkBoxCont}>
                        
                        <input type="checkbox" name='durationCBox' 
                            //value={showCorrectOpt}
                            value={checkboxDuration.display}
                            style={{...innerStyle.checkBox, marginLeft:'15px'}}
                            onChange={toggleTimeDuration}
                            checked={checkboxDuration.display}
                            />
                        <label htmlFor="display" >Set Time Duration</label>
                        <div style={{display:checkboxDuration.display?'flex':'none', marginLeft:'5px'}}>
                            <input placeholder='10' 
                                style={innerStyle.duVal}
                                maxLength={2} inputMode="numeric"/>
                            <select name="durationSel"
                                onChange={duSelectionHandler}
                                value={checkboxDuration.selVal}
                                className="mr"
                                //disabled={checkboxDuration.display? false : true}
                                >
                                <option value={10}>10sec</option>
                                <option value={3}>3sec</option>
                                <option value={5}>5sec</option>
                                <option value={7}>7sec</option>
                                <option value={12}>12sec</option>
                                <option value={15}>15sec</option>
                                <option value={17}>17sec</option>
                                <option value={20}>20sec</option>
                                <option value={25}>25sec</option>
                                <option value={30}>30sec</option>
                                <option value={35}>35sec</option>
                                <option value={40}>40sec</option>
                                <option value={45}>45sec</option>
                                <option value={50}>50sec</option>
                                <option value={55}>55sec</option>
                                <option value={60}>60sec</option>
                                <option value={70}>70sec</option>
                                <option value={80}>80sec</option>
                                <option value={90}>90sec</option>
                                <option value={100}>100sec</option>
                                <option value={110}>110sec</option>
                                <option value={120}>120sec</option>
                            </select>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className={styles.btnsCont}>
                <div className={btnEffect}
                    onClick={shuffleQuesHandler}>
                        Shuffle Questions
                </div>
                <div className={btnEffect}
                    onClick={shuffOptsHandler}>
                        Shuffle options
                </div>
            </div>
        </>
    );
}


export default BtnsMain


const innerStyle ={
    container:{
        backgroundColor: 'seashell',
    },
    checkBoxCont:{
        backgroundColor:'transparent',
        alignContent:'center',
        alignItems:'center',
        display:'flex'
    },
    checkBox:{
        height:'15px'
    },
    duVal:{
        width:'20px',
        marginLeft:'10px',
        display:'none',
    }
}
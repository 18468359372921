import { configureStore } from '@reduxjs/toolkit';
import questionsReducer from "./reducers/questionsReducer";
import composReducer from "./reducers/composReducer";
import userReducer from './reducers/userReducer';
import playerReducer from './reducers/playerReducer';



export const store = configureStore({
  reducer: {
    questionsReducer,
    composReducer,
    userSettings: userReducer,
    playerReducer,
  },
})
import React from "react";
import './HeaderStyle.css';
import { useSelector, useDispatch} from "react-redux";
import { disShowHeaderSbar } from "../../context/reducers/composReducer";
import errorURL from "../../assets/sound/errorSound.mp3";



const HeaderSnackbar =()=>{
    const dispatch = useDispatch();
    const {showHeaderSbar} = useSelector((state) => state.composReducer);
    const [showCont, setShowCont] = React.useState('none');
    const [show, setShow] = React.useState('');

    function showSnackbar() {
        setShowCont('block');
        setShow('HeaderSbar_Show');
        setTimeout(fadeOut, 4000);
        const audios = new Audio(errorURL); audios.play();
        dispatch(disShowHeaderSbar({...showHeaderSbar, show:false}))

        function fadeOut(){
            setShow('');
            setShowCont('none');
        }
      }
    
    React.useEffect(()=>{
        if(showHeaderSbar.show){
            showSnackbar();
        }
    },[showHeaderSbar]);
    
    return(
        <div style={{position:'relative', display:showCont}}>
            <div style={styles.snackcont}>
                
            </div>
        </div>
    );
}


const styles = {
    snackcont:{
        backgroundColor:'transparent',
        width:'100%',
        //height:'150px',
        position:'absolute',
    },
    btn:{
        position:'absolute', bottom:0,
        display:'none'
    }

}


export default HeaderSnackbar
import styles from '../Styles.module.css';
import React, {  useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {disOptFormat, disOptIsSelected, disSelectedOpt, disCurrentDisplayQ, disPrevOptsFormat,} from  "../../../../context/reducers/questionsReducer";
import { getOptionsFormat } from '../../../../modules/optionsFormat';
import { disPlayerClock } from '../../../../context/reducers/composReducer';
import {disCheckbokDuration} from '../../../../context/reducers/userReducer';

const Options=()=>{
    const dispatch = useDispatch();
    const {selectedQues, optsFormat, playbackStarted,currentDisplayQ,
      prevOptsFormat, pickedQues }= useSelector((state) => state.questionsReducer);
    const {checkboxDuration } = useSelector((state) => state.userSettings);
    const {playerClock, optsSettings} = useSelector((state) => state.composReducer);
    const selQues = selectedQues;
    const startGameIsOn = true;

    const borderGen = {
      borderWidth: '4px',
      borderStyle: 'solid',
      fontSize: `${optsSettings.fontSize}px`,
    }

    const addToPrevOptFormats =(qNo, fmt)=>{
      //preOptsFormat: {'0': {'1': fmt, '2': fmt, '3': fmt, '4': fmt, }, }= {queNo:{opts}}
      const newFormat = {...prevOptsFormat, [qNo]: fmt, qStatus:{...prevOptsFormat.qStatus}};
      dispatch(disPrevOptsFormat(newFormat));
    }

    const selectedOptionHandler=(selOpt)=>{
      if(startGameIsOn){
        if(!pickedQues.playbackStarted){
          if(!currentDisplayQ.ansChecked){ //Cant pick option ANSWER IS CHECKED
            dispatch(disSelectedOpt(selOpt));
          
            const currentQ = {...currentDisplayQ, optIsSelected:true, optNoSelected:selOpt};
            dispatch(disCurrentDisplayQ(currentQ));
            
            const selOptFmt = getOptionsFormat(selOpt, "SELECTIONFMT");
            dispatch(disOptFormat(selOptFmt));
            addToPrevOptFormats(selectedQues.sn, selOptFmt);
            dispatch(disOptIsSelected(true));
            //pausePlayerTimer();
            dispatch(disPlayerClock({...playerClock, playerTimer:2}));
            dispatch(disCheckbokDuration({...checkboxDuration, startCountDn:false}))
          }
        }else{
          //PLABACK
        }
      }else{alert("Game is not on")}
    }
    
    return(
        <>
          <div className={styles.optionPart1}>
              <HorizontalLine flex={2}/>
                <p style={{...borderGen, ...optsFormat['1']}}
                  onClick={()=>selectedOptionHandler(1)}>
                  A. {selQues[1]}
                </p>
              <HorizontalLine flex={0.5}/>
              <HorizontalLine flex={0.5}/>
                <p style={{...borderGen, ...optsFormat['2']}}
                  onClick={()=>selectedOptionHandler(2)}>
                  B. {selQues[2]}
                </p>
              <HorizontalLine flex={2}/>
          </div>
          <div className={styles.optionPart1}>
              <HorizontalLine flex={2}/>
                <p style={{...borderGen, ...optsFormat['3']}}
                  onClick={()=>selectedOptionHandler(3)}>
                  C. {selQues[3]}
                </p>
              <HorizontalLine flex={0.5}/>
              <HorizontalLine flex={0.5}/>
                <p style={{...borderGen, ...optsFormat['4']}}
                  onClick={()=>selectedOptionHandler(4)}>
                  D. {selQues[4]}
                </p>
              <HorizontalLine flex={2}/>
          </div>
        </>
    );
}


export default Options;





const HorizontalLine=(props)=>{

    return(
        <div style={{...innerStyle.hLine, flex:props.flex}}></div>
    );
}


const innerStyle ={
    hLine:{
        display: 'flex',
        border: '2px solid white',
    },
};

import React from "react";
import { useSelector, } from 'react-redux';
import SampleFileDownload from "../../upload/components/DownloadSampleFile";

const PlayGuide =({closePlayGuide, anyUploadFile})=>{


    return(
        <div style={{...styles.container,
            backgroundColor:anyUploadFile? 'rgb(200, 200, 250)' : '',}}>
            {anyUploadFile && <span style={styles.close} onClick={closePlayGuide}>X</span>}
            <h2 style={styles.header}>Play Guide</h2>
            <h3 style={styles.headerSub}>Quiz Questions File Upload</h3>
            <h4 style={styles.text1}>{text1}</h4>
            <SampleFileDownload/><br/>
            {txts1.map((txt, i)=>{
                const style1 = styles.text2;
                const style2 = styles.text3;
                return <div style={i===0? style2 : style1} key={`${i},txt1`}>{txt}</div>
            })}
            {txts3.map((txt, i)=>{
                const style1 = styles.text2;
                const style2 = {...styles.text3, marginTop:'10px'};
                const element1 = <div style={i===0? style2 : style1} key={`${i},txt1`}>{txt}</div>;
                const element2 = <ModifiedText1 key={`${i},txt1`}/>;
                const element3 = <ModifiedText2 key={`${i},txt1`}/>;
                return i===2? element2 : i ===4? element3 : element1
            })}
            <div style={{paddingTop:'5px', paddingLeft:'10px'}}>
                <h3 style={styles.headerSub}>Selection Guide</h3>
                {txts2.map((txt, i)=>{
                    return <div style={styles.text2} key={`${i},txt2`}>{txt}</div>
                })}
            </div>
        </div>
    );
}

const ModifiedText1 =()=>{
    return(
        <div style={{...styles.text2,}}>
            The Header, being the first line must have the following texts separated with a single Tab: S/N
                <TabText/> Question<TabText/>Option 1<TabText/>Option 2<TabText/>Option 3<TabText/>Option 4
        </div>
    );
}
const ModifiedText2 =()=>{
    return(
        <div style={{...styles.text2,}}>
            3. Beside the header, each line should hold a single question and options, each being separated by a single tab in this order: 
            serial-number<TabText/>question<TabText/>first-option<TabText/>second-option
            <TabText/>third-option<TabText/>forth-option.  (6 columns in a line)
        </div>
    );
}

const TabText =()=>{
    return(<span style={styles.tab}>Tab</span>
    );
}

const text1 = "To begin, upload your quiz questions file from 'File Management Section'. The file format must be 'csv', 'xlsx or txt' and you can upload up to 5 files.";
const text1b = `File Format: xlsx and csv`;
const text2 = `A valid xlsx or csv file should have a single 'Sheet' with the following formats:`; 
const text3 = `1. A row header with 6 columns: | S/N | Question | Option 1 | Option 2 | Option 3 | Option 4 |`; 
const text4 = `2. Beside the header, each row should hold a single question and options in this order: | serial-number | question | first-option | second-option | third-option | forth-option | (6 columns in a row).`;
const text5 =   `The First Option MUST BE THE CORRECT OPTION.`; 
const text6 =  `You will be able to reshuffle the options and questions before play`;
const text7 = `3. None of the column in a question row must be empty, ie, without value`;
const text8 = `4.  The serial number must be numbered correctly`;

const txt1b = `File Format: txt`;
const txt2 = `A valid txt file should have the following formats:`; 
const txt3 = `1. The Header, being the first line must have the following texts separated with a single Tab: S/N Question Option 1 Option 2 Option 3 Option 4`; 
const txt4 = `2. Tab separates the text in a line into columns which makes it 6 columns`; 
const txt5 = `3. Beside the header, each line should hold a single question and options, each being separated by a single tab in this order: serial-number question first-option second-option third-option forth-option.  (6 columns in a line)`;
const txt6 =   `The First Option MUST BE THE CORRECT OPTION.`; 
const txt7 =  `You will be able to reshuffle the options and questions before play`;
const txt8 = `4. None of the column in a question line must be empty, ie, without value`;
const txt9 = `5.  The serial number must be numbered correctly`;


const text9 = `Uploaded files will be displayed on 'Uploaded Files' Section`;
const text10 = `1. Afetr upload, click on a file to select it to play. The selected file will be displayed on 'Selected File Preview' Section.
                'Shuffle Questions' & 'Shuffle Options' buttons will be activated when a file is selected and you can shuffle as many times as you want`;
const text11 = `2. Use the 'Check' buttons to display or hide Questions and Options`;
const text12 =  `3. To make the system monitor the time duration to answer each question, check the "Set Time Duration" box and select time duration (time duration is in seconds). The time starts counting down when a question is picked and stops when an option is selected. If the count down elapsed before the an option is picked, a stoppage bell will be rang.`;
const text13 = `4. Press 'Load File' button to load the selected file for play. The 'Load File' button is activated when a file is selected.`;
const text14 = `5. Play button appears when the file is 100% loaded`;

const txts1 = [text1b, text2, text3, text4, text5, text6, text7, text8,];
const txts2 = [text9, text10, text11, text12, text13, text14];
const txts3 = [txt1b, txt2, txt3, txt4, txt5, txt6, txt7, txt8, txt9];

const styles = {
    container:{
        /*display:'flex',
        padding:'10px',
        flexDirection:'column',
        marginRight:'10px',
        marginBottom:'10px',
        position:'relative',*/
        padding:'20px',
        fontSize:'13px',
        //height:'55vh',
        //overflowY:'scroll',
        margin:'0 15px 10px 10px',
        borderRadius:'10px',
        //width:'90%',
        position:'relative',
    },
    header:{
        justifyContent:'center', display:'flex',
        alignText:'center',
        color:'blue', textShadow:'2px 2px yellow',
        marginTop:'-5px',
    },
    headerSub:{
        justifyContent:'flex-start', display:'flex',
        alignText:'center',
        color:'maroon',
        marginTop:'0px',
    },
    text1:{
        marginTop:'-7px',
        marginBottom:'5px',
        color:'navy',
        paddingBottom:'5px',
    },
    text2:{
        color:'navy',
        paddingBottom:'5px',
    },
    text3:{
        color:'navy',
        paddingBottom:'5px',
        backgroundColor:'ivory', padding:'5px',
        width: '150px', fontWeight:'bold',
        paddingTop:0,
    },
    close:{
        color:'red', fontWeight:'bold',
        position:'absolute',
        right:'20px',
        cursor:'pointer',
        fontSize:'16px',
    },
    tab:{
        //backgroundColor:'#efefef',
        padding:'0 1px',
        width:'20px',
        color:'gray',
        backgroundColor:'ghostwhite',
        border:'2px solid #bbb',
        borderRadius:'2px',
        borderBottom: '3px solid #aaa'
        
    }
}



export default PlayGuide
import React, {useEffect, useState, useRef} from "react";
import { validateExcelFile } from "./validateFile";
//import GlobalContext from "../../../../context/context";
//import XLSX from "xlsx";
//import * as XLSX from 'xlsx/xlsx.mjs';

import {createQuesObjFromArrays} from '../../modules/functionModules';
import { useSelector, useDispatch } from 'react-redux';
import { disNewFileUpload, disResetFileUpload, disFileDetails, disFileUploadError} from "../../context/reducers/userReducer";
var XLSX = require("xlsx");
//import { SET_USERPROFDATA, SET_NEWFILEUPLOAD, SET_RESETFILEUPLOAD } from "../../../../context/actions/action_Login";

function FileUploader(props){
 const dispatch = useDispatch();
 const {newFileUpload,resetFileUpload, newFileUploadPrev, fileUploadError, userFetchedData } = useSelector((state) => state.userSettings);
 const [uploadStatus, setUploadStatus] = useState(false);
 const [fileData, setFileData] = useState({raw:'', processed:''});
 const [fileUploadMsg, setFileUploadMsg] = useState('');
 const [padBot, setPadBot] = useState(25);

  const ref = useRef();
  const resetInputHandler=()=>{
    ref.current.value = "";
    setUploadStatus("");
    setFileUploadMsg('');
    setPadBot(25);
    dispatch(disNewFileUpload({...newFileUpload, uploadStatus: ''}));
  }

  function errorMsgHandler(){
      dispatch(disFileUploadError({...fileUploadError, show:true}));
  }

  useEffect(()=>{
      const resetInput = newFileUploadPrev.fileName;
      if(resetInput){resetInputHandler()}
  }, [newFileUploadPrev]);

  useEffect(()=>{
    ref.current.value = "";
    //setUploadStatus("");
    setFileUploadMsg('');
    setPadBot(25);
  },[resetFileUpload]);

  function handleChange(e) {
    const files = e.target.files;
    const fileExtension = e.target.value.split('.')[1];
    const fileUploaded = e.target.value.length > 0? 1 : '';
    if(userFetchedData.length < 5){
      if (files && files[0]){
        const file = e.target.files[0];
        const fileName = e.target.files[0].name;
        readSpreedSheetFile(file, fileUploaded, fileName, fileExtension);
      }
      setUploadStatus(fileUploaded);
    }else{
      resetInputHandler();
      alert('Number of maximum files upload is exceeded')
    }
  }

  function readSpreedSheetFile(file, fileUploaded, fileName, fileType) {
    /* Boilerplate to set up FileReader */
    //const [fileData, setFileData] = useState('');
    let uploadMsg = "";
    const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        //console.log(data);
        const errorMsg = validateExcelFile(data, fileType);
        dispatch(disFileUploadError({msg:errorMsg}));
        if(errorMsg.length> 0){
          //data = [['S/N', 'Ques', 'Opt1', 'Opt2', 'Opt3', 'Opt4'], [],[]]
          uploadMsg = "Invalid File";
          ref.current.value = "";
          setFileUploadMsg(uploadMsg);
          setUploadStatus('');
          //console.log(errorMsg);
          setPadBot(0);
          return
        }else{
          uploadMsg = "File upload successful";
          
          const prossData = createQuesObjFromArrays(data);
          const newFileData = {
            uploadStatus:fileUploaded, fileName:fileName,dyFileName:fileName, fileLen:data.length-1,
            rawData:data, prosData:prossData,};
          setFileData({raw:data, processed:prossData});
          dispatch(disNewFileUpload(newFileData));
          dispatch(disResetFileUpload());
          dispatch(disFileDetails({fileName:fileName, fileLen:data.length-1,}));
          setFileUploadMsg(uploadMsg);
          
          }
      };
      if(rABS){
        reader.readAsBinaryString(file);
        }else {
            reader.readAsArrayBuffer(file);
    }
  }


    return (
      <>
        <form style={{...innerStyle.cont, paddingBottom:padBot+'px'}}>
            <label htmlFor="file" style={props.labelTopStyle}>
              Upload new questions file
            </label>
            <br/>
            <input
              type="file"
              accept={SheetJSFT}
              onChange={handleChange}
              name="testing"
              style={props.inputStyle}
              ref={ref}
            />
            <br/>
            <label htmlFor="file" style={{
              color:'green'}}>
              {uploadStatus? 'File upload successful' : ''}
              
            </label>
        </form>
        <div style={{...innerStyle.errorMsgCont, display:fileUploadMsg.includes('Invalid')? 'flex' : 'none'}}>
            {fileUploadMsg} 
            <span style={innerStyle.errorBtn} onClick={errorMsgHandler}>See Error</span>
          </div>
      </>
    );
}

export default FileUploader

const innerStyle={
  cont:{
    paddingLeft:'10px'
  },
  errorBtn:{cursor:'pointer', color:'white',fontSize:'13px',
    backgroundColor:'maroon',padding:'1px 10px',
    marginTop:'5px', marginLeft: '7px', borderRadius:'5px',
    display:'flex', width:'60px', 
  },
  errorMsgCont:{
    color:'red',
    flexDirection:'row', justifyContents:'center',
    alignItems:'center',
    paddingBottom:'10px',
    paddingLeft:'15px',
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "csv",
  "xls",
  "txt",
  /*"xlsb",
  "xlsm",
  "xml",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"*/
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");
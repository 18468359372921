import React from 'react';
import { useSelector, } from 'react-redux';
import { MdTimer } from 'react-icons/md';
import Clock from './Clock';


const PlayerTimer =({playerClock})=>{
    //const {selectedQues, playText, currentDisplayQ}= useSelector((state) => state.questionsReducer);
    //const isAnswered = currentDisplayQ.ansChecked;

    return(
        <div style={innerStyle.container}>
            <MdTimer size={40} color="yellow"/>
            <Clock playerClock={playerClock}/>
            {playerClock.playerTimer}
        </div>
    );
}


const innerStyle ={
    container:{
        width:'100px', height:'40px', color:'coral',
        backgroundColor:'#003',
        position:'absolute',
        left:'5px', border:'2px solid red', borderRadius:'5px',
        marginTop:'20px',
        padding:'0 5px',
        display:'flex', flexDirection:'row', justifyContents:'center',
        alignItems:'center',
    }
};
export default PlayerTimer

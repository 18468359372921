import React from "react";
import NavIndex from './navigation/Index';



function App() {

  return (
    <NavIndex/>
  );
}

export default App;



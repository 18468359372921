import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { disDeleteFile,} from '../../../../context/reducers/userReducer';

const DeleteFileHandler =({file})=>{
 const dispatch = useDispatch();

 const deleteFileHandler=(file)=>{
    if(window.confirm(
        `Do you want to delete file - ${file.fileName}?`)){
        //updatePreviewIfFile(file);
        dispatch(disDeleteFile(file));
    }
 }
 
 return(
    
        <div
            style={innerStyle.span}
            onClick={()=>deleteFileHandler(file)}>
            <Icon.TrashFill color='red' size={18}/>
        </div>
 );
}

const innerStyle ={
    span:{
        marginTop:'-2px',
        padding: '1px 5px 0px 5px',
    }
}
export default DeleteFileHandler

import {a, a1, b, b1, c, c1, d, d1, e, e1, f, f1, g, g1, h, h1} from '../assets/data/questionsData';



let contQues = [a, b, c, d, e, f, g, h];
let contOpt = [a1, b1, c1, d1, e1, f1, g1, h1];

const createQuesionsArr =() =>{
    const arrCont = [];
    for(var i = 0; i< contQues.length; i++){
        const shufArr = shuffleArray(contOpt[i]);
        const shufRes = [contOpt[i][0], ...shufArr];
        let newArr = {sn: i, ques: contQues[i],
            ans: shufRes[0],
            a: shufRes[1], b: shufRes[2], 
            c: shufRes[3], d: shufRes[4],};
            arrCont.push(newArr);
    }
    //console.log(arrCont);
    return arrCont
}


function shuffleArray(array){
    const newArr = array.slice();
    for(var i =newArr.length - 1; i > 0; i--){
        var rand = Math.floor(Math.random() * (i+1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr
}
const shuffleArrayHandler =()=>{
    const arr = c1;
    const shufArr = shuffleArray(arr);
    const shufRes = [arr[0], ...shufArr];
    //setSelectedItem(shufRes);
}
const selectRandomItem =(arr)=>{
    const randItem = arr[Math.floor(Math.random() * arr.length)];
    return randItem
}

//prosData = [{sn:'', question:'',answer:'','1':'','2':'','3':'','4':''},{}]
const createQuesObjFromArrays =(arr)=>{
    //arr = [['S/N', 'Ques', 'Opt1', 'Opt2', 'Opt3', 'Opt4'], [1. Q1, Opt1, Opt2, Opt3, Opt4],[]]
    //Create an object from each inner array (rows) and push to the questions_Obj
    const questions_Obj = [];
    for(var i = 0; i < arr.length; i++ ){
        const innerArr = arr[i];
        const ans = innerArr[2];
        const shuffleOpts = shuffleArray(innerArr.slice(2,6));
        const eachRow = {
            sn: innerArr[0], 
            question: innerArr[1],
            answer:innerArr[2],  
            '1': shuffleOpts[0], '2': shuffleOpts[1], 
            '3': shuffleOpts[2], '4': shuffleOpts[3]};
        questions_Obj.push(eachRow);
    }

    return questions_Obj
}


const createQuesObjFromManyArrays =(obj)=>{
    //obj = {ques: genQues, opt1: genOpt1, opt2: genOpt2, opt3: genOpt3, opt4: genOpt4}
    const questions_Obj = [];
    for(var i = 0; i < obj.ques.length; i++ ){
        const opts = [obj.opt1[i], obj.opt2[i], obj.opt3[i], obj.opt4[i], ];
        const shuffleOpts = shuffleArray(opts);
        const eachRow = {
            sn: i+1, 
            question: obj.ques[i],
            answer:obj.opt1[i],  
            '1': shuffleOpts[0], '2': shuffleOpts[1], 
            '3': shuffleOpts[2], '4': shuffleOpts[3]};
        questions_Obj.push(eachRow);
    }

    return questions_Obj
}

export {createQuesionsArr, shuffleArray, createQuesObjFromArrays, createQuesObjFromManyArrays}
import React from 'react';
import styles from '../Styles.module.css';
import PreviewQuestions from './components/PreviewQuestions';
import QuestionLoader from '../../../utils/questionLoader/QuestionLoader';
import BtnsMain from './components/BtnsMain';
import { useSelector, useDispatch} from 'react-redux';
import * as Icon from 'react-bootstrap-icons';
import Tooltip from '../../../utils/tooltip/ToolTip';
import { disQuesLoader,} from "../../../context/reducers/userReducer";
import { disQuizQuesObj, } from "../../../context/reducers/questionsReducer";
import {disNewFileUploadPrev, disFileDetails,} from "../../../context/reducers/userReducer";
import GamePlayingContainer from './components/GamePlayingContainer';
import GameTitle from './components/GameTitle';

const IndexView=()=>{
    const dispatch = useDispatch();
    const {newFileUploadPrev, playGame} = useSelector((state) => state.userSettings);

    function cancelPrevFile(){
            dispatch(disQuizQuesObj({prevQues:[], fileName:'', dyFileName:'', play:true}));
            dispatch(disNewFileUploadPrev({correctOptColor:null, prevQues:[], fileName:'', dyFileName:'', fileNo:''}));
            dispatch(disFileDetails({fileName:'', fileLen:''})); 
            dispatch(disQuesLoader({loadingPer: '', loadingVal: '', loaderWidth:0}));
    }
    return(
        <div style={innerStyle.container}>
            <p className={styles.selectedFilePrev}>Selected File Preview</p>
            <div className={styles.ltSelectedCont}>
                <div className={styles.ltSelectedQText}>
                    Selected file:  
                    <span className={styles.quesSelected_Input}>
                        {newFileUploadPrev.fileName?
                            <span>No.{newFileUploadPrev.fileNo}- </span> : null
                            }
                        {newFileUploadPrev.fileName}
                    </span>
                    <div style={{float: 'right', visibility: newFileUploadPrev.fileName? 'visible' : 'hidden'}}>
                        <Tooltip text="Cancel selected file">
                            <Icon.TrashFill size={18} color="red" onClick={cancelPrevFile}/>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <BtnsMain/>
            <div>
                {playGame.start? <GamePlayingContainer/> :
                    <QuestionLoader/>
                }
            </div>
            <GameTitle/>
            <div>
                <PreviewQuestions/>
            </div>
        </div>
    );
}


export default IndexView


const innerStyle ={
    container:{
        backgroundColor: 'seashell',
        marginTop:'-10px',
        height:'100%',
    },

}


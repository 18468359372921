import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import PlayGuide from "./PlayGuide";
import {disFileUploadError, disToggleShowUserPlayGuide} from "../../../../context/reducers/userReducer";
import ErrorMessages from "./ErrorMessages";

const PreviewQuestions =()=>{
    const dispatch = useDispatch();
    const {newFileUploadPrev, checkboxMyPage, userFetchedData, 
            userPlayGuide, fileUploadError} = useSelector((state) => state.userSettings);
    const {correctOptColor, prevQues,} = newFileUploadPrev;
    const isPrevQues = prevQues? prevQues : [];
    const anyUploadFile = userFetchedData.length > 0? true : false;
    function closePlayGuide(){
        dispatch(disToggleShowUserPlayGuide(false));
    }
    const correctOptionCheck=(optsArr)=>{
        const correctOpt = 
        optsArr[1]===optsArr[0]? '1' :
        optsArr[2]===optsArr[0]? '2' : 
        optsArr[3]===optsArr[0]? '3' :
        optsArr[4]===optsArr[0]? '4' : 0;
        return correctOpt
    }
    const filteredNo =isPrevQues.filter((item)=>{
        const check = item.sn > 0; 
        return check
   });
    const closeErrorMessages =()=>{
        dispatch(disFileUploadError({...fileUploadError, show:false}))
    } 
    const showQuestions = 
     (<>
        {
        filteredNo.map((item, index)=>{
            const ans = item.answer;
            const correctOpt = correctOptionCheck([ans, item['1'],item['2'],item['3'],item['4']]);
            
            return(<div style={{paddingBottom:'8px'}} key={`${index}+new`}>
                <div>
                    <span style={{fontWeight:'bold'}}>Question {item.sn}.</span>{item.question}
                </div>
                <button style={{...innerStyle.btns, 
                    backgroundColor:correctOpt==='1'? correctOptColor : null}}>
                    A.{item['1']}
                </button>
                <button style={{...innerStyle.btns, 
                    backgroundColor:correctOpt==='2'? correctOptColor : null}}>
                    B.{item['2']}
                </button>
                <button style={{...innerStyle.btns, 
                    backgroundColor:correctOpt==='3'? correctOptColor : null}}>
                    C.{item['3']}
                </button>
                <button style={{...innerStyle.btns, 
                    backgroundColor:correctOpt==='4'? correctOptColor : null}}>
                    D.{item['4']}
                </button>
            </div>);})
        }   
    </>);
    const hideQuestions = 
            (<div style={innerStyle.hideQuestions}>
                <h2>Check "Display Questions" button above to view the questions</h2>
                <h3>You can reshuffle the questions and options before loading to play</h3>
            </div>);

    const selectFileInfo = 
            (<div style={innerStyle.hideQuestions}>
                <h2>Select a File to Preview and Load to Play</h2>
            </div>);
    const playGuide = <PlayGuide closePlayGuide={closePlayGuide} anyUploadFile={anyUploadFile}/>;
    var displayCont = ""; 
    
    if(userPlayGuide){
       displayCont = <PlayGuide closePlayGuide={closePlayGuide} anyUploadFile={anyUploadFile}/>;
    }else{
        if(anyUploadFile){ //File(s) upload
            if(isPrevQues.length > 0){  //File Selected
                if(checkboxMyPage.display){
                    displayCont = showQuestions
                }else{
                    displayCont = hideQuestions
                }
            }else{displayCont = selectFileInfo}
        }else{ 
            displayCont = playGuide 
        }
    }
    //const userPlayGuides = fileUploadError.show? false : userPlayGuide;

    return(
        <div style={innerStyle.cont}>
          {fileUploadError.show? 
            <ErrorMessages 
                errorMsg={fileUploadError}
                closeErrorMessages={closeErrorMessages}/>
          : displayCont}
        </div>
    );
}

export default PreviewQuestions


const innerStyle={
    cont:{
        backgroundColor:'rgb(209, 238, 247)',
        padding:'10px 0 0 10px',
        fontSize:'13px',
        height:'68vh',
        overflowY:'scroll',
        margin:'0 10px 0 10px',
        borderRadius:'10px',
        position:'relative',
    },
    btns:{
        marginRight:'5px',
        fontSize:'12px',
    },
    hideQuestions:{
        display:'flex', justifyContent:'center', 
        flexDirection:'column',
        alignContent:'center', alignItems:'center',
        paddingTop:'20px', padding: '10px',
        color:'blue',
        textAlign:'center'
    },
}
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    //isLogin: false,
    //activePage: 'Home',
    confirmationMsg: {display:'none', msg:'Confirm', response:'',type:''},
    playerModalBox: {display:'none',},
    playersInputs: {noOfPlayers:'',eachScore:'',totalQues:'',
        createBoard:false, noOfPlayersArr:[], startPlayer:0},
    playersSettings:{
      noOfRounds:'',
      playerResult: 1, // 1 or 0
      fullBarPercent: 100, 
      eachBarStep:'',
      currentplayer:'',
      currentRound:1,
      quesCount:0,
      cumTotalScore:0,
      nextPlayer:'',
    },
    //scoreboard:{display:false},
    playersObj:[],
    clickingTrack: {checkAnsBtn:0},
    playersSettingsNew:{cumTotalScore:0},
}



export const playerReducer = createSlice({
  name: 'playerSelect',
  initialState,
  
  reducers: {
    disConfirmationMsg: (state, action) => {
      state.confirmationMsg = action.payload
    },
    disPlayerModalBox: (state, action) => {
      state.playerModalBox = action.payload
    },
    disPlayersSettings: (state, action) => {
      state.playersSettings = action.payload
    },
    disPlayersObj: (state, action) => {
        state.playersObj = action.payload
      },
    disClickingTrack: (state, action) => {
        state.clickingTrack = action.payload
      },
  },
})


export const { disConfirmationMsg, disPlayerModalBox, disPlayersSettings,
    disPlayersObj, disClickingTrack, } = playerReducer.actions

export default playerReducer.reducer
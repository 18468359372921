import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showHeaderSbar: {show:false, msg:'Snackbar message'},
  headerModalSnack: {show:false, msg1:'Snackbar message',msg2:"Continue?", 
      okayBtn: "Continue", action:""},
  playerClock: {playerTimer:'', started:false},
  startGameTimer:{start:false, sec:0, mins:0},
  countDownTimer:{start:false, },
  headerTitle:"",
  quesSettings:{fontSize:25},
  optsSettings:{fontSize:22},
}



export const composReducer = createSlice({
  name: 'composSelect',
  initialState,
  
  reducers: {
    disShowHeaderSbar: (state, action) => {
      state.showHeaderSbar = action.payload
    },
    disPlayerClock: (state, action) => {
      state.playerClock = action.payload
    },
    disHeaderModalSnack: (state, action) => {
      state.headerModalSnack = action.payload
    },
    disCountDownTimer: (state, action) => {
      state.countDownTimer = action.payload
    },
    disStartGameTimer: (state, action) => {
      state.startGameTimer = action.payload
    },
    disHeaderTitle: (state, action) => {
      state.headerTitle = action.payload
    },
    disQuesSettings: (state, action) => {
      state.quesSettings = action.payload
    },
    disOptsSettings: (state, action) => {
      state.optsSettings = action.payload
    },

    
  },
})


export const { disShowHeaderSbar, disPlayerClock, disHeaderModalSnack,
  disCountDownTimer, disStartGameTimer, disHeaderTitle,
 disQuesSettings, disOptsSettings } = composReducer.actions

export default composReducer.reducer
import React from 'react';
import styles from '../Styles.module.css';
import CheckAnswer from './CheckAnswer';
import Options from './Options';



const QuesDisplayContainer =({selectedQues, quesSettings})=>{
    const showScoreBoard = false;
    const quesContSet ={flex:showScoreBoard? 1:1,};
    const optCont ={marginTop:showScoreBoard? '-20px':'',};
    

    return(
        <>
        
            <div style={{...innerStyle.quesCont, ...quesContSet}}>
                <div>
                    <div className={styles.quesContSub}>
                        <div className={styles.lineH}></div>
                        <p className={styles.ques}
                            style={{fontSize:`${quesSettings.fontSize}px`}}>
                            {selectedQues.question? selectedQues.question : "Question"}
                        </p>  
                        <div className={styles.lineH}></div>
                    </div>
                    <div style={{...optCont}}>
                        <Options/>
                    </div>
                </div>
                <div style={innerStyle.checkAnsCont}>
                {selectedQues.question? <CheckAnswer/> : null}
                </div>
            </div>
                    
        </>  
    );
}


const innerStyle ={
    
  quesCont:{
    color: 'white',
    border: '2px solid coral',
    margin: '35px 5px 20px 5px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    alignContent: 'space-around',
    },
    checkAnsCont:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    }
};
export default QuesDisplayContainer

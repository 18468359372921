import React from 'react';
import shortPeepURL from "../../../../../assets/sound/beepSoundShortLow.mp3";
import longPeepURL from "../../../../../assets/sound/beepSoundLong.mp3";
import bellURL from "../../../../../assets/sound/bellSound.mp3";
import { useSelector, useDispatch } from 'react-redux';
import { MdTimer } from 'react-icons/md';
import {disCheckbokDuration} from '../../../../../context/reducers/userReducer';
import useWindowDimensions from '../../../../../utils/hooks/useWindowDimensions';

var timer_on = false;
var timeout;
const PieSpinnerTimer =()=>{
    const dispatch = useDispatch();
    const {width} = useWindowDimensions();
    const {checkboxDuration } = useSelector((state) => state.userSettings);
    const {selVal, startCountDn,} = checkboxDuration;
    const [animLoader, setAnimLoader] = React.useState({mid:0, x:0, y:-125});
    const [counterSec, setCounterSec] = React.useState(0);
    const [durationSec, setDurationSec] = React.useState(10);
    const [countStarted, setCountStarted] = React.useState(false);
    const durationCountDown = durationSec - counterSec;
    const dPath = `M 0 0 v -125 A 125 125 1 ${animLoader.mid} 1 ${animLoader.x} ${animLoader.y} z`;
    //let r = 100, cy = 0, cx = 0;
    //const path2 =   `M ${cy}, ${cx} m ${-r}, 0 a ${r}, ${r} 0 1, 0 ${r*2}, 0 a ${r}, ${r} 0 1, 0 ${-r*2}, 0`;
    
    var a= 0, pie =Math.PI, t = 1000;
    const perDrawVal = 360/durationSec;
    const vals = {penLastDraw: 360 - perDrawVal};
    //console.log(checkboxDuration);

    function draw(){
        //a += perDrawVal;
        a %= 360;
        let r = (a * pie/180),
            x = Math.sin(r) * 125,
            y = Math.cos(r) * -125,
            mid = (a > 180)? 1 : 0;
            //anim = 'M 0 0 v -125 A 125 125 1' + mid + '1' + x + '' + y + 'z';
        
        setCounterSec(prev=>prev+1);
       timeout =  setTimeout(draw, t);
       setAnimLoader({mid:mid, x:x, y:y});

       a += perDrawVal;
    }

    function startDraw(){
        if(!timer_on){
            timer_on = true;
            resetDraw();
            draw();
            setCountStarted(true);
        }
    }
    function stopDraw(){
        clearTimeout(timeout);
        timer_on = false;
        setCountStarted(false);
    }
    function resetDraw(){
        clearTimeout(timeout);
        timer_on = false;
        setAnimLoader({mid:0, x:0, y:-125});
        setCounterSec(0);
        setCountStarted(false);
        //dispatch(disCheckbokDuration({...checkboxDuration, startCountDn:false}));
        //console.log([animLoader.mid, animLoader.x, animLoader.y])
    }
    React.useEffect(()=>{
        //Playing Sound
        if(countStarted){
            if(durationCountDown === 1){
                const audios = new Audio(longPeepURL); audios.play();
            }else if(durationCountDown===0){
                const audios = new Audio(bellURL); audios.play();
            }else{
                const audios = new Audio(shortPeepURL); audios.play();
            }
        }
    },[durationCountDown]);

    React.useEffect(()=>{
        if(durationCountDown ===0){
            resetDraw();
            dispatch(disCheckbokDuration({...checkboxDuration, startCountDn:false}));
        }
    },[counterSec]);

    React.useEffect(()=>{
        setDurationSec(selVal? parseInt(selVal) : 10)
    },[selVal]);
    //const d ={animLoader};
    React.useEffect(()=>{
        //if(checkboxDuration.countDnStarted){
            if(startCountDn){
                startDraw();
            }else{stopDraw()}
        //}
    },[startCountDn]);

    const clockSettings = width > 1400? innerStyle.contlarge : innerStyle.contDef;

    return(
        <div style={{...innerStyle.container, ...clockSettings}}>
           <MdTimer size={140} color="yellow" style={innerStyle.alarm}/>
           <p style={innerStyle.duVal}>
                {durationCountDown}
            </p>
           <svg width={125} height={125} viewBox="0 0 300 300" style={{zIndex:2}}>
                <circle cx="150" cy="165" r="110" stroke="red" 
                    strokeWidth="3" fill="white" />
                <path fill='red' stroke='lime' strokeWidth={4}
                   transform="translate(150, 165) scale(.83)" 
                   d={dPath} />
                
            </svg> 
        </div>
    );
}


const innerStyle ={
    container:{
        //width:'150px', height:'150px',
        backgroundColor:'#003',
        position:'absolute',
        border:'2px solid red', borderRadius:'5px',
        marginLeft:'0px',
        padding:'5px 0px 0px 0px',
        display:'flex', flexDirection:'row', justifyContent:'center',
        alignItems:'center', alignContent:'center',
    },
    contDef:{left:'5px', marginTop:'0px', transform:'scale(0.8)'},
    contlarge:{left:'35px', marginTop:'45px', transform:'scale(1.3)'},
    svgboarder:{fill: "#00517a"},
    svgloader:{fill: "#0088cc"},
    duVal:{
        fontWeight:'bold',
        position:'absolute',
        color:'mediumblue', fontSize:'32px',
        left:'22px',
        top:'20px',
        //backgroundColor:'lime',
        width:'80px',
        textAlign:'center',
        zIndex:3, 
    },
    alarm:{
        position:'absolute',
        zIndex:0
    }
};
export default PieSpinnerTimer



/*
var loader = document.getElementById('loader')
  , border = document.getElementById('border')
  , α = 0
  , π = Math.PI
  , t = 30;

(function draw() {
  α++;
  α %= 360;
  var r = ( α * π / 180 )
    , x = Math.sin( r ) * 125
    , y = Math.cos( r ) * - 125
    , mid = ( α > 180 ) ? 1 : 0
    , anim = 'M 0 0 v -125 A 125 125 1 ' 
           + mid + ' 1 ' 
           +  x  + ' ' 
           +  y  + ' z';
  //[x,y].forEach(function( d ){
  //  d = Math.round( d * 1e3 ) / 1e3;
  //});
 
  loader.setAttribute( 'd', anim );
  border.setAttribute( 'd', anim );
  
  setTimeout(draw, t); // Redraw
})();
*/
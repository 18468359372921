import React, { useState, useEffect } from "react";
import './Style.css';


const Clock = ({playerClock}) => {
  const [seconds, setSeconds] = useState(0);
  const [mins, setMins] = useState(0);
  const [isActive, setIsActive] = useState(false);
  //const  playerTimer = playSets.playerTimer;

  function toggle() {
    setIsActive(!isActive);
  }
  function startCount() {
    setIsActive(true);
  }
  function stopCount() {
    setIsActive(false);
  }

  function reset() {
    setSeconds(0);
    setMins(0);
    setIsActive(false);
  }
  function counterCall() {
    var newSec = (seconds) => seconds + 1;
    setSeconds(newSec);
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(counterCall, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(()=>{
    //Timer: Start-1, Pause-2, Reset-0
        if(playerClock===0){
            reset();
        }else if(playerClock ===1){startCount();
        }else if(playerClock ===2){stopCount();}
    
  },[playerClock]);

  return (
    <>
      <div className={
        `PlayerCounterCont ${playerClock===2? 'CounterFadeInOut' : ""}`
        }>
          {seconds<10? "0"+seconds:seconds}
          <span className="CounterFadeInOutSpan"
            style={{color: 'lime'}}>s</span>
      </div>
    </>
  );
};

export default Clock;
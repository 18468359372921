import React, {useState,  } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {AiOutlineHome, AiFillSetting} from 'react-icons/ai';
import {MdViewCarousel} from 'react-icons/md';
import {BsInfoCircle} from 'react-icons/bs';
//import Tooltip from '../../utils/tooltip/ToolTip';
import { useSelector, useDispatch } from 'react-redux';
import { disShowHeaderSbar } from '../../context/reducers/composReducer';
import { disToggleShowUserPlayGuide } from '../../context/reducers/userReducer';

const HeaderBar =({anyUploadFile,})=> {
    const dispatch = useDispatch();
    const {pickedQues}= useSelector((state) => state.questionsReducer);
    const {showHeaderSbar, headerTitle}= useSelector((state) => state.composReducer);
    const {userPlayGuide} = useSelector((state) => state.userSettings);
    const navigate = useNavigate();
    const location = useLocation(); 
    let activeRoute = location.pathname; 
    activeRoute = activeRoute.toString().substring(1);
    const [iconHomeCol, setIconHomeCol] = useState('ivory');
    function togglePlayGuide(){
        dispatch(disToggleShowUserPlayGuide());
    }

    const gamePlayingError =()=>{
        const msg ="A question is currently selected! Finish playing the selected question";
        dispatch(disShowHeaderSbar({...showHeaderSbar, msg:msg, show:true}))
    }
    const goTo=(screen)=>{
        if(screen === "settings"){
            if(pickedQues.pickedIsAns){
                navigate(`/settings`);  
            }else{gamePlayingError()}
        }else{navigate(`/${screen}`);}
        
    }
    const goHomeHandler=()=>{
        if(pickedQues.pickedIsAns){
            navigate('/');
        }else{gamePlayingError()}
    }


    return(
        <div style={innerStyle.container}
        >
            <AiOutlineHome 
                size={32}
                onClick={goHomeHandler}
                color={iconHomeCol}
                style={{paddingLeft:'20px', cursor:'pointer'}}
                onMouseEnter={()=>setIconHomeCol(pickedQues.pickedIsAns? "blue" : 'red')}
                onMouseLeave={()=>setIconHomeCol(pickedQues.pickedIsAns? "ivory" : 'red')}
                />
            
            <h2 style={{color:'yellow',}}>{headerTitle?.toUpperCase() || 'QUIZZAPP'}</h2>
            <div style={{...innerStyle.loginCont,}}>
                {activeRoute==="settings" &&
                    <MdViewCarousel 
                        size={32}
                        onClick={()=>goTo("play")}
                        color={"ivory"}
                        style={{paddingLeft:'10px', cursor:'pointer'}}
                        
                        />
                }
                
                
                {activeRoute==="play" &&
                    <AiFillSetting 
                        size={32}
                        onClick={()=>goTo("settings")}
                        color={pickedQues.pickedIsAns? "ivory" : 'red'}
                        style={{paddingLeft:'10px', cursor:'pointer'}}
                    
                        />
                }
                    {activeRoute==="settings" && !anyUploadFile &&
                            <BsInfoCircle 
                                size={28}
                                onClick={togglePlayGuide}
                                color={userPlayGuide? "yellow" :"ivory"}
                                style={{paddingLeft:'20px', cursor:'pointer'}}
                            
                                /> 
                    
                    }
            </div>
         
        </div>     
    );
}


const innerStyle ={
    container:{
        //width:'100%',
        height:'50px',
        backgroundColor: 'rgb(2, 3, 34)', //'rgb(24, 61, 56)',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        alignContents:'center',
        zIndex:1,
        borderBottom:'2px solid white'
        //backgroundColor:'ivory'
    },
    loginCont:{
        backgroundColor:'',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        alignContents:'center',
        paddingRight:'20px',
    },
};
export default HeaderBar

import React from "react";
import SampleFileDownload from "../../upload/components/DownloadSampleFile";

const ErrorMessages =({errorMsg, closeErrorMessages})=>{

    return(
        <div style={styles.cont}>
            <span style={styles.close} onClick={closeErrorMessages}>X</span>
            <h2 style={styles.header}>List of Error Messages</h2>
            {errorMsg.msg.map((msg, i)=>{
                return (
                    <p key={`${i}error`}>
                        {i+1}. {msg}
                    </p>
                );
            })
            }
            <p style={{color:'blue'}}>Download sample file as a guide</p>
            <SampleFileDownload/>
        </div>
    );
}



const styles={
    cont:{
        backgroundColor:'rgb(250, 200, 200)',
        padding:'10px',
        fontSize:'13px',
        height:'55vh',
        overflow:'scroll',
        margin:'0 10px 10px 10px',
        borderRadius:'10px',
        width:'90%',
        position:'relative',
    },
    header:{
        textAlign:'center', color:'maroon'
    },
    close:{
        color:'red', fontWeight:'bold',
        position:'absolute',
        right:'20px',
        cursor:'pointer',
        fontSize:'16px',
    }
}

export default ErrorMessages
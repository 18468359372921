
const defaultFmat = {color:'white', backgroundColor:'rgb(8, 3, 20)', borderColor:'white'};
const selectedFmat = {color:'black', backgroundColor:'skyblue', borderColor:'mediumblue'};
const wrongOptFmat = {color:'black', backgroundColor:'coral', borderColor:'blue'};
const correctFmat = {color:'black', backgroundColor:'lime', borderColor:'seagreen'};
const selOptIsCorrectFmat = {color:'black', backgroundColor:'lime', borderColor:'mediumblue'};
const defaultFmatAll = {'1': defaultFmat,'2': defaultFmat, '3': defaultFmat, '4': defaultFmat,};


function getOptionsFormat(selOpt, type){
    let returnedFormat = defaultFmat;
    const selOptFormat = {
        '1': selOpt===1? selectedFmat : defaultFmat,'2': selOpt===2? selectedFmat : defaultFmat,
        '3': selOpt===3? selectedFmat : defaultFmat, '4': selOpt===4? selectedFmat : defaultFmat,
    }
    
    switch(type){
        case "DEFAULTFMT":
            returnedFormat = defaultFmatAll;
            break;
        case "SELECTIONFMT":
            returnedFormat = selOptFormat;
            break;
        case "SELECTIONWRONGFMT":
            returnedFormat = wrongOptFmat;
            break;
        case "CORRECTFMT":
            returnedFormat = correctFmat;
            break;
        case "SELECTIONCORRECTFMT":
            returnedFormat = selOptIsCorrectFmat;
            break;

        default: 
            returnedFormat = defaultFmat;
    }

    return returnedFormat
}



export {getOptionsFormat}
import React  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { disSelectedQues, disPickedQues, disPlayText,
    disOptFormat, disOptIsSelected } from "../../../../context/reducers/questionsReducer";
import { getOptionsFormat } from '../../../../modules/optionsFormat';
import styles from '../Styles.module.css';
import '../Styles.css'; // Import regular stylesheet


const IndexNonAvail =({ mapList, colorSet, bColor, 
    loadingDone, cursor, isPrevQues})=>{
 const dispatch = useDispatch();
 const spaceIfLoaded = loadingDone? 'space-around' : 'space-around';
 const {selectedQues, quizQues_Obj, prevOptsFormat, pickedQues, currentDisplayQ}= useSelector((state) => state.questionsReducer);
 const {answer} = selectedQues;
 //const mapListSorted = mapList?.sort();

 const correctOption=()=>{
    const correctOpt = 
        selectedQues['1']===answer? 1 :
        selectedQues['2']===answer? 2 : 
        selectedQues['3']===answer? 3 :
        selectedQues['4']===answer? 4 : 0;
    return correctOpt
}

 const prevQuestionHandler=(quesNo)=>{
    const isPlayNo = quesNo === pickedQues.pickedNo? true : false;
    if(isPlayNo){
        dispatch(disPickedQues({...pickedQues, playbackStarted:false}));
        dispatch(disPlayText({header: "LET'S PLAY",header2:'', bgColor: 'rgb(24, 61, 56)'}));
    }else{
        //PLAYBACK
        dispatch(disPickedQues({...pickedQues, playbackStarted:true}));
        dispatch(disPlayText({header: "PLAY BACK", bgColor: 'tomato'}));  
    }
    var selNo = quizQues_Obj.prevQues.filter((item)=>{
        return item.sn === quesNo
    });
    selNo = {...selNo[0]};
    dispatch(disSelectedQues(selNo));

    const defaultFmat = getOptionsFormat(null, 'DEFAULTFMT');
    const optFmtForSelected = prevOptsFormat[`${quesNo}`];
    dispatch(disOptFormat(optFmtForSelected? optFmtForSelected : defaultFmat));
    //dispatch(disOptIsSelected(false));
 }

  return(
    <div style={innerStyle.container}>
        <span style={innerStyle.avail}>Non-available: {mapList.length}</span>
        <div style={innerStyle.subcont}>
            {
                mapList.map((item,index)=>{
                    const lastItem = ""; //mapList.length-1 === index? null :",";
                    return (
                    <CustomBtn2
                        key={index+'myKeys'}
                        item={item}
                        prevQuestionHandler={()=>prevQuestionHandler(item)} 
                        colorSet={colorSet} pickedQues={pickedQues} 
                        lastItem={lastItem} bColor={bColor}/>
                    )
                })}
        </div>
     </div>
 );
}


const CustomBtn1 = ({item, prevQuestionHandler, colorSet, pickedQues, lastItem, bColor})=>{

   return( 
        <div>
            <button 
                onClick={prevQuestionHandler}
                style={{cursor: 'pointer', color:colorSet, 
                        backgroundColor:pickedQues.pickedNo===item? "blue" : bColor, 
                    }}>
                    {item}{lastItem} 
            </button>
        </div>
   );
}
const CustomBtn2 = ({item, prevQuestionHandler, colorSet, pickedQues, lastItem, bColor})=>{
   

   return( 
        <div 
            className={styles.quesBtns_nonAvail}
                onClick={prevQuestionHandler}
            style={{color:'white', 
                backgroundColor:pickedQues.pickedNo===item? "blue" : '#f22', 
            }}>
            <p className={styles.quesBtnsTextNonAvail}>
                {item}
            </p>
        </div>
   );
}

const innerStyle ={
    container:{
        marginTop:'5px',
        border: '2px solid ivory',
        borderRadius: '10px',
        backgroundColor:'ivory'
    },
    subcont:{
        padding: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        overflowY: 'scroll',
        height: '20vh',
    },
    avail:{
        color:'maroon', fontWeight:'bold',
        justifyContent:'center', display:'flex',
        borderBottom: '2px solid gray',
        padding: '5px', fontSize:'20px',
    }
};


export default IndexNonAvail
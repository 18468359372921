import { createSlice } from '@reduxjs/toolkit';
const optFormat = {color:'white', backgroundColor:'rgb(8, 3, 20)', borderColor:'white'};
const prevOptsFormatDefault = {'0': {'1': optFormat, '2': optFormat, '3': optFormat, '4': optFormat}, qStatus:{}};
const optFormatDefault = {'1': optFormat, '2': optFormat, '3': optFormat, '4': optFormat};

const initialState = {
    selectedOpt: '',
    correctOpt: '',
    selectedQues: {},
    displayQues: '',
    pickedQues:{pickedNo:'', playbackNo:'', playbackStarted:false, pickedIsAns: true},
    quizQues_Obj: {prevQues:[], fileName:'', dyFileName:'', play:true},
    availQuesList:[],
    nonAvailQuesList:[],
    loaderWidth:0,
    quesNoList:[],
    optsFormat: {'1': optFormat, '2': optFormat, '3': optFormat, '4': optFormat},
    prevOptsFormat:{'0': {'1': optFormat, '2': optFormat, '3': optFormat, '4': optFormat}, qStatus:{}}, //Question No. 
    correctOptFormat: {},
    loadingPercent: {loadingPer:0, loadingVal:0},
    playText: {header: "LET'S PLAY", header2: "", bgColor: 'rgb(24, 61, 56)'},
    optIsSelected: false,
    startQuizQues: false,
    playbackStarted:false,
    currentDisplayQ:{qNo:'', optIsSelected:false, optNoSelected:'', 
        ansChecked:false, correctOpt: '', rightAnswer:''},
}


export const questionsReducer = createSlice({
  name: 'questionsReducer',
  initialState,
  
  
  reducers: {
    disSelectedOpt: (state, action) => {
      state.selectedOpt = action.payload
    },
    disCorrectOpt: (state, action) => {
      state.correctOpt = action.payload
    },
    disSelectedQues: (state, action) => {
      state.selectedQues = action.payload
    },
    disDisplayQues: (state, action) => {
      state.displayQues = action.payload
    },
    disQuizQuesObj: (state, action) => {
      state.quizQues_Obj = action.payload
    },
    disAvailQuesList: (state, action) => {
      state.availQuesList = action.payload
    },
    disNonAvailQuesList: (state, action) => {
      state.nonAvailQuesList = action.payload
    },
    disLoaderWidth: (state, action) => {
      state.loaderWidth = action.payload
    },
    disQuesNoList: (state, action) => {
      state.quesNoList = action.payload
    },
    disOptFormat: (state, action) => {
      if(action.payload === 'RESET'){
        state.optsFormat = optFormatDefault
      }else{state.optsFormat = action.payload}
    },
    disPrevOptsFormat: (state, action) => {
      if(action.payload === 'RESET'){
        state.prevOptsFormat = prevOptsFormatDefault
      }else{state.prevOptsFormat = action.payload}
    },
    disCorrectOptFormat: (state, action) => {
      state.correctOptFormat = action.payload
    },
    disLoadingPercent: (state, action) => {
      state.loadingPercent = action.payload
    },
    disPlayText: (state, action) => {
      state.playText = action.payload
    },
    disOptIsSelected: (state, action) => {
      state.optIsSelected = action.payload
    },
    disStartQuizQues: (state, action) => {
      state.startQuizQues = action.payload
    },
    disPlaybackStarted: (state, action) => {
      state.playbackStarted = action.payload
    },
    disCurrentDisplayQ: (state, action) => {
      state.currentDisplayQ = action.payload
    },
    disPickedQues: (state, action) => {
      state.pickedQues = action.payload
    },
  },
})


export const { disSelectedOpt, disCorrectOpt, disSelectedQues, disDisplayQues, disQuizQuesObj,
      disAvailQuesList, disNonAvailQuesList, disLoaderWidth, disQuesNoList, disOptFormat,
      disPrevOptsFormat, disCorrectOptFormat, disLoadingPercent, disPlayText, disOptIsSelected,
      disStartQuizQues, disPlaybackStarted, disCurrentDisplayQ, disPickedQues} = questionsReducer.actions

export default questionsReducer.reducer

//Questions
let a1=  "The Plaka is the oldest quarter of which city?";
let a2= " What is an axolotl?";
let a3= " The Panama Canal was officially opened by which US president?";
let a4= " In which opera did Maria Callas make her last appearance at Covent Garden?";
let a5= " After Adam, Eve, Cain and Abel who is the next person mentioned in the Bible?";
let a6= " What is a kudzu?";
let a7= " Outlawed from 1603 to 1774, which Scottish clan was known as the 'Faceless Clan'?";
let a8= " From which country does tennis player Andres Gomez, winner of the 1990 French Championships, come?";
let a9= " Which US city is located on the Maumee River at Lake Erie?";
let a10=" Lisbon stands at the mouth of which river?";
let a11=" What is the longest river in France?";
let a12=" In which Australian state is Darwin?";
let a13=" Apart from Rome, how many cities in Italy have a population of over one million?";
let a14=" What in America is the Palmetto state?";
let a15=" In which ocean is Madagascar?";
let a16=" In which US city is the Sears Tower?";
let a17=" Galena is an ore of which metal?";
let a18=" Which sign of the zodiac comes between Leo and Libra?";
let a19=" Which planet is said to influence the way we communicate?";
let a20=" Are you a fire, air, water or earth sign if you were born under Aquarius?";
let a21=" Which star sign is the first sign of the zodiac?";
let a22=" If you were born on New Year's Day, which is your astrological sign?";
let a23=" What is the ruling planet of Scorpio?";
let a24=" Pisces and Scorpio are two of the water signs, name the third";
let a25=" At which sport in the 1960s did Peggy Fleming become a household name?";
let a26= " Who revolutionised high jumping when he used his flop technique to win an Olympic gold medal in Mexico in 1968?";
let a27=" What nationality was tennis player John Newcombe?";
let a28=" About which golfer did the great Bobby Jones remark";
let a29=" Which tennis player, famous for her two-handed backhand and baseline-dominated play was nicknamed 'The Ice Maiden'?";
let a30=" Who was the Argentinian racing driver ( 1911 - 1995 ) who won five world championships and was team leader of Mercedes-Benz, Alfa Romeo, Ferrari and Maserati?";
let a31=" Which Italian jockey rode his first winner Lizzy Hare, at Goodwood in 1987?";
let a32=" With which baseball team did Jose DiMaggio achieve icon status in the 1940s?";
let a33=" What type of cheese is Stilton?";
let a34=" What do you add to milk to make porridge?";
let a35=" What is minestrone?";
let a36=" What is bottled tomato sauce called?";
let a37=" What colour is gin?";
let a38=" Which fruit is covered with toffee at a fairground?";
let a39=" Which nuts are used to make marzipan?";
let a40=" What meat dish is Cumberland famous for?";
let a41=" Which British monarch succeeded Queen Victoria?";
let a42=" Richard III died at which battle?";
let a43=" Who was the last viceroy of India?";
let a44=" Which English monarch married Eleanor of Aquitaine?";
let a45=" Who was the last wife of Henry VIII?";
let a46=" Which country did Britain fight in the War of Jenkins' Ear?";
let a47=" Which King George did Prince Regent become?";
let a48=" At the Siege of Mafeking, who led the British forces?";
let a49=" The House of Lancaster kings were all called what?";
let a50=" Apart from Mad George, which kinder nickname did George III have?";

//Option 1
let a51=" Athens";
let a52=" A nerve in the brain";
let a53=" Calvin Coolidge";
let a54=" 'Carmen'";
let a55=" Enoch";
let a56=" Antelope";
let a57=" MacGregor";
let a58="Ecuador";
let a59=" Toledo";
let a60=" Tagus";
let a61=" Loire";
let a62=" Northern Territory";
let a63=" Three";
let a64=" South Carolina";
let a65=" Indian";
let a66=" Chicago";
let a67=" Lead";
let a68=" Virgo";
let a69=" Mercury";
let a70=" Earth";
let a71=" Aries";
let a72=" Capricorn";
let a73=" Venus";
let a74=" Cancer";
let a75=" Figure skating";
let a76=" Dick Fosbury";
let a77=" Australian";
let a78=" Jack Nicklaus";
let a79=" Chris Evert";
let a80=" Juan Manuel Fangio";
let a81=" Frankie Dettori";
let a82=" New York Yankees";
let a83=" Blue";
let a84= " Oats";
let a85= " Soup";
let a86= " Ketchup";
let a87= " Colourless";
let a88= " Apple";
let a89= " Almonds";
let a90= " Sausage";
let a91= " Edward VII";
let a92= " Bosworth Field";
let a93= " Lord Louis Mountbatten";
let a94= " Henry II";
let a95= " Catherine Parr";
let a96= " Spain";
let a97= " George IV";
let a98= " Robert Baden-Powell";
let a99= " Henry";
let a100= " Farmer George";

//Option 2
let a101= " Prague";
let a102= " A multi-axled vehicle";
let a103= " Herbert Hoover";
let a104= " 'Tosca'";
let a105= " Jubal";
let a106= " Bird";
let a107= " Campbell";
let a108= "Peru";
let a109= " Detroit";
let a110= " Duoro";
let a111= " Seine";
let a112= " Western Australia";
let a113= " Two";
let a114= " Louisiana";
let a115= " Pacific";
let a116= " Boston";
let a117= " Copper";
let a118= " Taurus";
let a119= " Venus";
let a120= " Water";
let a121= " Capricorn";
let a122= " Cancer";
let a123= " Pluto";
let a124= " Sagittarius";
let a125= " Tennis";
let a126= " Mick Cadbury";
let a127= " Canadian";
let a128= " Tiger Woods";
let a129= " Steffi Graf";
let a130= " Alberto Ascari";
let a131= " Diego Dettori";
let a132= " Chicago Bears";
let a133= " Green";
let a134= " Cruel";
let a135= " Noodles";
let a136= " HP sauce";
let a137= " Blue";
let a138= " Banana";
let a139= " Chestnuts";
let a140= " Deep-fried Mars Bars";
let a141= " Edward VI";
let a142= " Barnet";
let a143= " George VI";
let a144= " Henry IV";
let a145= " Catherine of Aragon";
let a146= " Ireland";
let a147= " George III";
let a148= " Colonel B T Mahon";
let a149= " Edward";
let a150= " Farmer Giles";

//Option 3
let a151= " Rome";
let a152= " A type of mortice lock";
let a153= " Theodore Roosevelt";
let a154= " 'Madame Butterfly'";
let a155= " Lamech";
let a156= " Jewish settlement";
let a157= " MacLeod";
let a158= "Portugal";
let a159= " Cleveland";
let a160= " Rio Grande";
let a161= " Rhone";
let a162= " Queensland";
let a163= " One";
let a164= " Alabama";
let a165= " Atlantic";
let a166= "St Louis";
let a167= " Zinc";
let a168= " Aries";
let a169= " Mars";
let a170= " Air";
let a171= " Taurus";
let a172= " Aries";
let a173= " Earth";
let a174= " Aquarius";
let a175= " Sprinting";
let a176= " Nick Fosdyke";
let a177= " Irish";
let a178= " Gary Player";
let a179= " Evonne Cawley";
let a180= " Giuseppe Farina";
let a181= " Uto Viaggi";
let a182= " Baltimore Batters";
let a183= " Hard";
let a184= " Sugar";
let a185= " A type of potato";
let a186= " Brown sauce";
let a187= " Green";
let a188= " Carrot";
let a189= " Hazelnuts";
let a190= " Ribs";
let a191= " Elizabeth II";
let a192= " Hastings";
let a193= " Prince Louis of Battenburg";
let a194= " Henry VI";
let a195= " Catherine Howard";
let a196= " France";
let a197= " George II";
let a198= " Fieldmarshal Bernard Montgomery";
let a199= " George";
let a200= " Big George";

//Option 4
let a201= " Vienna";
let a202= " A species of salamander";
let a203= " Woodrow Wilson";
let a204= " 'La Boheme'";
let a205= " Zillah";
let a206= " Climbing plant";
let a207= " MacDonald";
let a208= "Spain";
let a209= " Buffalo";
let a210= " Seine";
let a211= " Gironde";
let a212= " Tasmania";
let a213= " Four";
let a214= " Florida";
let a215= " Arctic";
let a216= " San Francisco";
let a217= " Iron";
let a218= " Capricorn";
let a219= " Saturn";
let a220= " Fire";
let a221= " Pisces";
let a222= " Leo";
let a223= " Saturn";
let a224= " Leo";
let a225= " Equestrian";
let a226= " Vic Foster";
let a227= " British";
let a228= " Peter Allis";
let a229= " Kim Clijsters";
let a230= " Jose Gonzales";
let a231= " Giovanni Forte";
let a232= " Cleveland Steelers";
let a233= " Mouldy";
let a234= " Whisky";
let a235= " Cheese";
let a236= " Piccalilli";
let a237= " Yellow";
let a238= " Pear";
let a239= " Walnuts";
let a240= " Bacon";
let a241= " George VII";
let a242= " York";
let a243= " Clive";
let a244= " Henry VIII";
let a245= " Anne of Cleves";
let a246= "Wales";
let a247= " George I";
let a248= " Louis Mountbatten";
let a249= " Charles";
let a250= " Happy George";


let genQues = [a1,	a2,	a3,	a4,	a5,	a6,	a7,	a8,	a9,	a10,	a11,	a12,	a13,	a14,	a15,	a16,	a17,	a18,	a19,	a20,	a21,	a22,	a23,	a24,	a25,	a26,	a27,	a28,	a29,	a30,	a31,	a32,	a33,	a34,	a35,	a36,	a37,	a38,	a39,	a40,	a41,	a42,	a43,	a44,	a45,	a46,	a47,	a48,	a49,	a50,];
let genOpt1 = [a51,	a52,	a53,	a54,	a55,	a56,	a57,	a58,	a59,	a60,	a61,	a62,	a63,	a64,	a65,	a66,	a67,	a68,	a69,	a70,	a71,	a72,	a73,	a74,	a75,	a76,	a77,	a78,	a79,	a80,	a81,	a82,	a83,	a84,	a85,	a86,	a87,	a88,	a89,	a90,	a91,	a92,	a93,	a94,	a95,	a96,	a97,	a98,	a99,	a100];
let genOpt2 = [a101,	a102,	a103,	a104,	a105,	a106,	a107,	a108,	a109,	a110,	a111,	a112,	a113,	a114,	a115,	a116,	a117,	a118,	a119,	a120,	a121,	a122,	a123,	a124,	a125,	a126,	a127,	a128,	a129,	a130,	a131,	a132,	a133,	a134,	a135,	a136,	a137,	a138,	a139,	a140,	a141,	a142,	a143,	a144,	a145,	a146,	a147,	a148,	a149,	a150];	
let genOpt3 = [a151,	a152,	a153,	a154,	a155,	a156,	a157,	a158,	a159,	a160,	a161,	a162,	a163,	a164,	a165,	a166,	a167,	a168,	a169,	a170,	a171,	a172,	a173,	a174,	a175,	a176,	a177,	a178,	a179,	a180,	a181,	a182,	a183,	a184,	a185,	a186,	a187,	a188,	a189,	a190,	a191,	a192,	a193,	a194,	a195,	a196,	a197,	a198,	a199,	a200];
let genOpt4 = [a201,	a202,	a203,	a204,	a205,	a206,	a207,	a208,	a209,	a210,	a211,	a212,	a213,	a214,	a215,	a216,	a217,	a218,	a219,	a220,	a221,	a222,	a223,	a224,	a225,	a226,	a227,	a228,	a229,	a230,	a231,	a232,	a233,	a234,	a235,	a236,	a237,	a238,	a239,	a240,	a241,	a242,	a243,	a244,	a245,	a246,	a247,	a248,	a249,	a250,];
let genQuestions ={ques: genQues, opt1: genOpt1, opt2: genOpt2, opt3: genOpt3, opt4: genOpt4};

export default genQuestions
import React, {useState,  } from 'react';
import './Styles.css';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '../../../../utils/tooltip/ToolTip';
import * as Icon from 'react-bootstrap-icons';
import {disQuesNoList, disNonAvailQuesList,  disQuizQuesObj,
} from "../../../../context/reducers/questionsReducer";
import {disNewFileUploadPrev, disFileDetails,disQuesLoader, disPlayGame} from "../../../../context/reducers/userReducer";
import { useNavigate } from 'react-router-dom';
import {disHeaderModalSnack} from "../../../../context/reducers/composReducer";

const GamePlayingContainer=()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {headerModalSnack } = useSelector((state) => state.composReducer);
    const {playGame, } = useSelector((state) => state.userSettings);

    const cancelGameCall =()=>{
            dispatch(disQuizQuesObj({prevQues:[], fileName:'', dyFileName:'', play:true}));
            dispatch(disNewFileUploadPrev({correctOptColor:null, prevQues:[], fileName:'', dyFileName:'', fileNo:''}));
            dispatch(disFileDetails({fileName:'', fileLen:''})); 
            dispatch(disQuesLoader({loadingPer: '', loadingVal: '', loaderWidth:0}));

            /**** Reset questions */
            dispatch(disQuesNoList([]));
            dispatch(disNonAvailQuesList([]));
            dispatch(disHeaderModalSnack({...headerModalSnack, action:''}));
            dispatch(disPlayGame({...playGame, start:false}));
    }       
    const cancelGamehandler = ()=>{
        dispatch(disHeaderModalSnack({...headerModalSnack, msg1:msg1, msg2:msg2, show:true}));
    }
   
    React.useEffect(()=>{
        if(headerModalSnack.action){ //If action button is PRESSED
            if(headerModalSnack.action === "CONTINUE"){
                cancelGameCall();
            }
        }

    },[headerModalSnack]);

    return(
        <div className='GamePlaying'>
            <div className='playingNotif'>
                <p>Game is playing</p>
                <span></span><span></span><span></span><span></span>
                <Tooltip text="Return to Game">
                    <Icon.ArrowLeftCircleFill 
                    size={24} color="blue"
                    style={innerStyle.filePlay}
                    onClick={()=> {navigate('/play');}}/>
                </Tooltip>
                <Tooltip text="Cancel on going game">
                    <Icon.XCircleFill 
                    size={24} color="red"
                    style={innerStyle.filePlay}
                    onClick={cancelGamehandler}/>
                </Tooltip>
            </div>           
        </div>
    );
}


export default GamePlayingContainer
const msg1 = `Currently playing game will be cancel!`;
const msg2 = `Continue?`;

const innerStyle ={
    container:{
        backgroundColor: 'seashell',
    },
    filePlay:{
        marginTop:'3px',
        marginLeft:'15px'
    },
    playCont:{
        display:'flex', flexDirection:'row',
        width:'65px',
        justifyContent:'space-between'
    }
    
}
import React from 'react';
import styles from './Styles.module.css';
import bgroundImg from "../../assets/images/background_Quiz.png";
import { useNavigate } from "react-router-dom";
import { client } from '../../client';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import {BiError} from 'react-icons/bi';
const img1 = 'https://picsum.photos/seed/picsum/200/300';
const img2 = "https://picsum.photos/536/354";
const img3 = "https://picsum.photos/200/300.jpg";



const Index =()=>{
    const navigate = useNavigate();
    const {width} = useWindowDimensions();
    const minScreenWidth = 799;
    React.useEffect(()=>{
        getPosts().then(res=>{
          //console.log(res)
        });
      },[]);

      
    return(
        <div className={styles.container} style={innerStyle.container}>
            <div className={styles.playDummyCont}
                onClick={()=>navigate("/settings")}>
                <p>
                    <span>Q</span>
                    <span>U</span>
                    <span>I</span>
                    <span>Z</span>
                    <span>Z</span>
                    <span>A</span>
                    <span>P</span>
                    <span>P</span>
                </p>
            </div>
            {width >minScreenWidth?
                <div className={styles.getStartedCont}>
                <p className={styles.getStarted}
                    onClick={()=>navigate('/settings')}>
                        Get started
                </p>
                <p className={styles.playMsg}>Enter to upload your questions to play</p>
            </div>
            :
            <div className={styles.screenErrorCont}>
                <p className={styles.screenError}>
                   <BiError size={40} color="red"/> Low Screen Width!
                </p>
                <p className={styles.screenErrorMsg}>Your screen width is {width}px! Minimum screen width to play the game is {minScreenWidth+1}px</p>
            </div>}


        </div>
    );
}


export default Index;

export async function getPosts() {
    const posts = await client.fetch('*[_type == "pastorCorner"]')
    return posts
  }

  

const innerStyle ={
    container:{
        minHeight: '100vh', backgroundColor:'#282c34',
        backgroundImage: `url(${bgroundImg})`,
        backgroundPosition:'center'
    },
}

function ImageSlider() {
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [images, setImages] = React.useState([img1, img2, img3]);
    const [slideInterval] = React.useState(5000);
  
    React.useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
      }, slideInterval);
  
      return () => clearInterval(intervalId);
    }, [slideInterval, images.length]);
  
    const currentImage = images[currentIndex];
  
    return (
      <img src={currentImage} alt={`Slide ${currentIndex}`} />
    );
  }
  

  
import React, { useState, useEffect } from "react";


//Game Start Clock
const Timer = ({startGame}) => {
  const [seconds, setSeconds] = useState(0);
  const [mins, setMins] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const startGameOn = startGame;

  function toggle() {
    setIsActive(!isActive);
  }
  function startCount() {
    setIsActive(true);
  }
  function stopCount() {
    setIsActive(false);
  }

  function reset() {
    setSeconds(0);
    setMins(0);
    setIsActive(false);
  }
  function counterCall() {
    var newSec = (seconds) => seconds + 1;
    if (seconds === 59) {
      var newMins = (mins) => mins + 1;
      setSeconds(0);
      setMins(newMins);
    }else{
      setSeconds(newSec); 
      setMins(mins=>mins);
    }
    //setSeconds(newSec);
    
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(counterCall, 1000);
    } else if (!isActive && seconds !== 0 
      && mins !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);


  useEffect(()=>{
    if(startGameOn){
        startCount();
    }else{reset();}

  },[startGame]);

  return (
    <>
      <div style={innerStyle.clockContainer}>
          {mins<10? "0"+mins : mins}:
          {seconds<10? "0"+seconds:seconds}
      </div>
    </>
  );
};



const innerStyle ={
    clockContainer:{
    color: 'lime', /*#17D4FE; "OrbitronLight";*/
    fontFamily: "DigitalClock",
    fontSize: 'x-large',
    width: '80px',
    textAlign: 'right',
    }
}

export default Timer;
import React from 'react';
import bgroundImg from "../../assets/images/background_Quiz.png";
import HeaderBar from "../../navigation/header/Index";
import IndexSelection from "./selection/Index";
import IndexView from './view/Index';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { useNavigate } from "react-router-dom";

const Index =()=>{
    const navigate = useNavigate();
    const {width} = useWindowDimensions();
    const minScreenWidth = 800;

    
    React.useEffect(()=>{
        if(width && width < minScreenWidth){
            navigate("/")
        }
      },[width, navigate]);
    
    return(
        <div style={innerStyle.container}>
         <div style={innerStyle.contViewHeader}>
            <HeaderBar showInfo={false}/>
            <IndexView/>
         </div>
         <div style={innerStyle.contSelec}>
            <IndexSelection/>
         </div>
        </div>
    );
}


export default Index;





const innerStyle ={
    container:{
        minHeight: '100vh', backgroundColor:'#282c34',
        backgroundImage: `url(${bgroundImg})`,
        backgroundPosition:'center',
        display:'flex',
        flex:1,
    },
    contViewHeader:{
        display:'flex',
        flex:3,
        flexDirection:'column',
    },
    contView: {
        backgroundColor:'rgb(240, 237, 232)',
        display: 'flex',
        flex: 1,   //4
        marginLeft:'50px',
    },
    contSelec:{
        display:'flex',
        flex:1.2,
    }
}
import React, { useContext, useEffect, useState } from 'react';
import styles from '../Styles.module.css';
import { useDispatch, useSelector,} from 'react-redux';
import correctURL from "../../../../assets/sound/correctSound.mp3";
import wrongURL from "../../../../assets/sound/wrongSound.mp3";
import { disOptFormat, disPrevOptsFormat,  disCurrentDisplayQ, disPickedQues,
    disPlayText} from "../../../../context/reducers/questionsReducer"; 
import { disShowHeaderSbar, disPlayerClock } from '../../../../context/reducers/composReducer';

const CheckAnswer =()=>{
    const dispatch = useDispatch();
    const {selectedQues, optIsSelected,selectedOpt, optsFormat, 
        prevOptsFormat,currentDisplayQ,pickedQues, playText
        }= useSelector((state) => state.questionsReducer);
    const {showHeaderSbar, playerClock} = useSelector((state) => state.composReducer);
    const {answer} = selectedQues;
    const startGameIsOn = true;

    const correctOption=()=>{
        const correctOpt = 
        selectedQues['1']===answer? '1' :
        selectedQues['2']===answer? '2' : 
        selectedQues['3']===answer? '3' :
        selectedQues['4']===answer? '4' : 0;
        return correctOpt
    }
    
    function resetPlayerTimer(){
        //Reset Timer. Reset = 0
        //const playerTimer = playSettings.playerTimer;
        //dispatchRexMain(SET_PLAYSETTINGS, {...playSettings,
        //    playerTimer: 0});
    }


    const setPrevOptFormat=(qNo, fmt, status)=>{
        //preOptsFormat: {'0': {'1': fmt, '2': fmt, '3': fmt, '4': fmt, }, }= {queNo:{opts}}
        const newFormat = {...prevOptsFormat, [qNo]: fmt, 
            qStatus:{...prevOptsFormat.qStatus, [qNo]: status}};
        return newFormat
    }
    const checkAnswerCall=()=>{
       let correctOpt = correctOption();
       const defaultFmat = {color:'white', backgroundColor:'rgb(8, 3, 20)', borderColor:'white'};
       const wrongOptFmat = {color:'black', backgroundColor:'coral', borderColor:'blue'};
       const correctFmat = {color:'black', backgroundColor:'lime', borderColor:'seagreen'};
       const selOptIsCorrectFmat = {color:'black', backgroundColor:'lime', borderColor:'mediumblue'};

        //Replace the correct option format
        const newForm = {...optsFormat, [selectedOpt]:wrongOptFmat, [correctOpt]: correctFmat};
        const newFormSelOptIsCorrect = {...optsFormat, [correctOpt]: selOptIsCorrectFmat};
        if(!pickedQues.playbackStarted){
            if(optIsSelected){
                const answerIsCorrect = selectedQues[selectedOpt] === answer;
                //Set settings for just answered question
                const currentQ = {...currentDisplayQ, ansChecked:true, 
                    correctOpt: correctOpt, rightAnswer: answerIsCorrect? true : false};
                dispatch(disCurrentDisplayQ(currentQ));
                resetPlayerTimer();
                dispatch(disPlayerClock({...playerClock, playerTimer:0}));
                dispatch(disPickedQues({...pickedQues, pickedIsAns: true}));
                dispatch(disPlayText({...playText, header: "",header2:'is Gone! Next', bgColor: 'green'}));
                    if(answerIsCorrect){
                        //Executed if chosen answer is correct
                            dispatch(disOptFormat(newFormSelOptIsCorrect));
                            //Save format
                            const newFmt = setPrevOptFormat(selectedQues.sn, newFormSelOptIsCorrect, 1);
                            dispatch(disPrevOptsFormat(newFmt));
                        //Play sound and scorePlayer
                        const audios = new Audio(correctURL); audios.play();

                        }else{
                        //Executed if chosen answer is wrong
                            dispatch(disOptFormat(newForm));
                            //Save format
                            const newFmt = setPrevOptFormat(selectedQues.sn, newForm, 0);
                            dispatch(disPrevOptsFormat(newFmt));
                            const audios = new Audio(wrongURL); audios.play();
                    }
            }else{
                const msg = 'Pick an option before viewing the answer';
                dispatch(disShowHeaderSbar({...showHeaderSbar, msg:msg, show:true}))
            }
        }else{
            //Eexecute when playback start
        }
    }
    function checkAnswerHandler(){
        if(startGameIsOn){
            if(!currentDisplayQ.ansChecked){checkAnswerCall()} //Call once
        }else{alert("Game is not on")}
    }


    return(
        <div className={styles.checkAnswer}
            onClick={checkAnswerHandler}>
            Check Answer
        </div>     
    );
}


const innerStyle ={
    container:{
        flex:1,
        //backgroundColor:'ivory'
    },
};

export default CheckAnswer;


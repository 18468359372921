import React from 'react';
import PlayerTimer from './clock/PlayerTimer';
import { MdPlayCircle } from 'react-icons/md';
import { useSelector,useDispatch } from 'react-redux';
import { disPickedQues, disPlayText, disSelectedQues,
    disOptFormat, disOptIsSelected } from '../../../../context/reducers/questionsReducer';
import { getOptionsFormat } from '../../../../modules/optionsFormat';
import PieSpinnerTimer from './clock/PieSpinnerTimmer';

const PlayHeader =()=>{
    const dispatch = useDispatch();
    const {selectedQues, playText, currentDisplayQ, prevOptsFormat,
        pickedQues, quizQues_Obj, availQuesList}= useSelector((state) => state.questionsReducer);
    const {checkboxDuration } = useSelector((state) => state.userSettings);
    const isAnswered = currentDisplayQ.ansChecked;
    const {playerClock} = useSelector((state) => state.composReducer);

    const backToPlayHandler=()=>{
        const quesNo = pickedQues.pickedNo;
        dispatch(disPickedQues({...pickedQues, playbackStarted:false}));
        dispatch(disPlayText({header: "LET'S PLAY", bgColor: 'rgb(24, 61, 56)'}));
        
        var selNo = quizQues_Obj.prevQues.filter((item)=>{
            return item.sn === quesNo
        });
        selNo = {...selNo[0]};
        dispatch(disSelectedQues(selNo));

        const defaultFmat = getOptionsFormat(null, 'DEFAULTFMT');
        const optFmtForSelected = prevOptsFormat[`${quesNo}`];
        dispatch(disOptFormat(optFmtForSelected? optFmtForSelected : defaultFmat));
        //dispatch(disOptIsSelected(false));
     }
    
    React.useEffect(()=>{
        if(availQuesList.length < 1){
            dispatch(disPlayText({header: "LET'S PLAY",header2:'', bgColor: 'rgb(24, 61, 56)'}));
        }
    },[availQuesList]);
    return(
        <div style={{
            ...innerStyle.headerCont}}>
            {checkboxDuration.display? 
                <PieSpinnerTimer/>
                :<PlayerTimer playerClock={playerClock.playerTimer}/>
             }
            <div style={innerStyle.cont}>
                <p style={{...innerStyle.header, backgroundColor:playText.bgColor}}>
                    {playText.header} QUESTION {selectedQues.sn} {playText.header2}
                </p>
                <div style={{...innerStyle.backToGame, visibility:pickedQues.playbackStarted? 'visible' : 'hidden'}}
                    onClick={backToPlayHandler}>
                    <MdPlayCircle size={32} color='blue'/>
                    <span style={innerStyle.backToGameNo}>{pickedQues.pickedNo}</span>
                </div>
            </div>
        </div>
    );
}


const innerStyle ={
    headerCont:{
        marginTop: '-10px',
        display: 'flex',
        justifyContent:'center',
        marginBottom:'-10px',
    },
    cont:{
        position:'relative',
        //backgroundColor:'lime',
        justifyContent:'center',
        display:'flex', flexDirection:'row',
        alignItems:'center',

    },
    backToGame:{
        position:'absolute',
        right:'-75px',
        backgroundColor:'silver',
        justifyContent:'center',
        display:'flex', flexDirection:'row',
        alignItems:'center',
    },
    backToGameNo:{
        margin:'5px', padding:'0 5px',
        fontWeight:'bold',
    },
    header:{
        fontWeight:'bold',
        fontSize:'20px',
        textAlign:'center',
        color:'white',
        padding:'10px 30px 10px 30px',
        border: '2px solid ivory',
        borderRadius: '10px',
    },
    round:{
        fontWeight:'bold',
        fontSize:'20px',
        textAlign:'center',
        color:'white',
    },
    rSpan:{
        padding:'10px',
        border: '2px solid ivory',
        borderRadius: '50%',
        backgroundColor:'maroon'
    },
    player:{
        fontWeight:'bold',
        fontSize:'20px',
        textAlign:'center',
        color:'white',
        padding:'10px',
        border: '2px solid ivory',
        borderRadius: '50%',
        backgroundColor:'maroon'
    },
};
export default PlayHeader

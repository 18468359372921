import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    usersProfData:{},
    newFileUpload:{uploadStatus:false, fileName:'', fileLen:'',  //const newFileData = {uploadStatus:'', fileName:'',dyFileName:'', fileLen:'', rawData:'', prosData:'',};
      rawData:'', prosData:'', },   //prosData = [{sn:'', question:'',answer:'','1':'','2':'','3':'','4':''}]
    newFileUploadPrev:{correctOptColor:null, prevQues:[], fileName:'', dyFileName:'', fileNo:''},
    userFetchedData:[],
    quesLoader: {loadingPer: '', loadingVal: '', loaderWidth:0},
    selectedQPrev:{},
    editFileName: true,
    resetFileUpload:0,
    checkboxMyPage:{display:true, correctOpt:false},
    deleteFile: {},
    fileDetails:{fileName:"", fileLen:""},
    userPlayGuide:false,
    fileUploadError:{msg:[], show: false},
    checkboxDuration:{display:false, selValue:10, startCountDn:false, countDnStarted:false},
    playGame:{start:false}
}



export const userReducer = createSlice({
  name: 'userSettings',
  initialState,
  
  reducers: {
    disUserProfData: (state, action) => {
      state.usersProfData = action.payload
    },
    disNewFileUpload: (state, action) => {
      state.newFileUpload = action.payload
    },
    disNewFileUploadPrev: (state, action) => {
      state.newFileUploadPrev = action.payload
    },
    disUserFetchedData: (state, action) => {
        state.userFetchedData = action.payload
      },
    disQuesLoader: (state, action) => {
        state.quesLoader = action.payload
      },
    disSelectedQPrev: (state, action) => {
        state.selectedQPrev = action.payload
      },
    disResetFileUpload: (state) => {
        state.resetFileUpload = state.resetFileUpload + 1
      },
    disCheckboxMyPage: (state, action) => {
        state.checkboxMyPage = action.payload
      },
    disDeleteFile: (state, action) => {
        state.deleteFile = action.payload
      },
    disFileDetails: (state, action) => {
        state.fileDetails = action.payload
      },
    disToggleShowUserPlayGuide:(state, action) => {
      state.userPlayGuide = action.payload? action.payload : !state.userPlayGuide
    },
    disFileUploadError:(state, action) =>{
      state.fileUploadError = action.payload
    },
    disCheckbokDuration:(state, action) =>{
      state.checkboxDuration = action.payload
    },
    disPlayGame:(state, action) =>{
      state.playGame = action.payload
    }
  },
})


export const { disUserProfData, disNewFileUpload, disNewFileUploadPrev,
        disUserFetchedData, disQuesLoader, disSelectedQPrev, 
    disResetFileUpload,  disCheckboxMyPage, disDeleteFile,
    disFileDetails, disToggleShowUserPlayGuide, disFileUploadError,
    disCheckbokDuration, disPlayGame} = userReducer.actions

export default userReducer.reducer
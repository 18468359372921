import React, {useState} from "react";
import styles from '../Styles.module.css';

const Tooltip = ({ children, text, bColor,fSize, textColor, leftAlign, tooltipStyles, ...rest }) => {
    const [show, setShow] = useState(false);
    

    const inStyle = {
      backgroundColor: bColor? bColor : 'rgba(0, 0, 0, 0.7)',
      color: textColor? textColor : '#fff',
      left: leftAlign? `${leftAlign}px` : '0px',
      fontSize: fSize? `${fSize}px` : '12px',
    };
    const col = bColor? bColor :'rgba(0, 0, 0, 0.7)';
    const arrowSet={
      borderColor: `transparent transparent ${col} transparent`,
      left: leftAlign? '90px' : '10%',
    }

    return (
      <div className={styles.tooltip_container}>
        <div className={show ? `${styles.tooltip_box} ${styles.visible}` : styles.tooltip_box}
          style={inStyle}>
          {text}
          <span className={styles.tooltip_arrow} style={arrowSet}/>
        </div>
        <div
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          {...rest}
          style={tooltipStyles}
        >
          {children}
        </div>
      </div>
    );
  };

  export default Tooltip

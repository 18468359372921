import React, { useEffect } from "react";
import HeaderBar from "../../navigation/header/Index";
import UploadIndex from "./upload/Index";
import IndexView from "./view/Index";
import {disToggleShowUserPlayGuide} from "../../context/reducers/userReducer";
import { useSelector, useDispatch} from 'react-redux';
import { disSelectedQues, disOptFormat,
    disPrevOptsFormat, } from "../../context/reducers/questionsReducer";
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { useNavigate } from "react-router-dom";

const Index =()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {userFetchedData,quesLoader} = useSelector((state) => state.userSettings);
    const anyUploadFile = userFetchedData.length > 0? true : false;
    const loaderWidth = quesLoader.loaderWidth;;
    const {width} = useWindowDimensions();
    const minScreenWidth = 800;
    React.useEffect(()=>{
        if(width && width < minScreenWidth){
            navigate("/")
        }
      },[width, navigate]);

    function showPlayGuide(){
        dispatch(disToggleShowUserPlayGuide());
    }


    useEffect(()=>{
        //Reset Play Display if loader is 0%
        if(loaderWidth===0){
            dispatch(disSelectedQues({}));
            dispatch(disOptFormat('RESET'));
            dispatch(disPrevOptsFormat('RESET'));
        }
    },[loaderWidth, dispatch]);

    return(
        <div style={innerStyle.container}>
            <HeaderBar togglePlayGuide={showPlayGuide} 
                anyUploadFile={anyUploadFile}
                />
            <div style={{
                flex:1, display:'flex',marginLeft:'5px',
                height:'92vh'}}>
                <div style={{flex:6,}}>
                  <IndexView/>     
                </div>
                <div style={{flex:3}}>
                  <UploadIndex/>
                </div>
            </div>
        </div>
    );
}


export default Index


const innerStyle ={
    container:{
        minHeight: '100vh', backgroundColor:'#282c34',
    },
}
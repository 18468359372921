import React from 'react';
import { MdAccessTime} from "react-icons/md";
import Timer from './clock/Clock';

const HeaderBar =({playGame})=>{
    const start = playGame.start;
    
    return(
        <>
            <div style={innerStyle.headBar}>
                <MdAccessTime color='yellow' size={28} 
                    />
                <div style={{color:'red'}}>
                {start? "ON" : "OFF"}
                </div>
                <Timer startGame = {start}/>
            </div>
            
        </>
    );
}


export default HeaderBar;


const innerStyle ={
    headBar:{
        height:'30px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        alignContent:'center',
        paddingRight:'10px',
        marginBottom:'5px',
        padding:'5px',
        border:'2px solid white',
        background: 'linear-gradient(rgb(100,105,105),rgb(5,5,15), rgb(5,5,15), rgb(5,5,15),rgb(105,105,105)',
        
    }
};
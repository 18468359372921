import React from "react"
import logo from '../logo.svg';
import '../App.css';
import { BrowserRouter,HashRouter, Route, Routes, Link} from "react-router-dom";
import Index from "../screens/home/Index";
import IndexSettings from "../screens/settings/Index";
import IndexPlay from "../screens/play/Index";
import { Provider } from 'react-redux';
import { store } from "../context/store";


export default function NavIndex() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
            <Route index path="/"  element={<Index/>} />
            <Route path="settings" element={<IndexSettings />} />
            <Route  path="play" element={<IndexPlay />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}


  
  function About() {
    return (
      <div className="App-header">
        <h1>This is the home page</h1>
      </div>
    );
  }
  
  function Contact() {
    return (
      <div className="App-header">
        <h1>This is the home page</h1>
      </div>
    );
  }
  


function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Link to="about" style={{color:'white'}}>Click to view our about page</Link>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      
    </div>
  );
}

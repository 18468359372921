//import sanityClient from '@sanity/client';
import {createClient} from '@sanity/client'

//import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: 'production',
  apiVersion: '2022-10-18',
  useCdn: false,
  token: process.env.REACT_APP_SANITY_TOKEN,
});